import {BrowserModule, HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {GestureConfig, MatDatepickerModule, MatNativeDateModule} from "@angular/material";

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {JwtInterceptor} from './service/jwt.interceptor';

import {AUTH_CONFIG} from './config/api';
import {SharedModule} from './shared/shared.module';
import {Routes} from "@angular/router";

import {BaseRowDef} from "@angular/cdk/table";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {AuthModule} from './modules/auth/auth.module';
import {ChatQuizModule} from "./pages/pages.module";

import {ChartsModule, ThemeService} from 'ng2-charts';

import {MatSliderModule} from '@angular/material/slider';

import { NgxCurrencyModule } from "ngx-currency";


import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

import {CarouselModule} from "ngx-owl-carousel-o";


//import {routerTransition} from "animations/router.animations";
//import {trigger, state, animate, style, transition} from '@angular/animations';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 'auto',
};

// Import your library
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

// import {DemoMaterialModule} from "./material.module";
// import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material";

@NgModule({
  declarations: [
    AppComponent,

  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        HttpClientModule,
        SharedModule,
        ChartsModule,
        AuthModule.forRoot(AUTH_CONFIG, {currentUserRoles: '/api/session/roles'}),
        ChatQuizModule,
        MatSliderModule,
        FormsModule,
        NgxCurrencyModule,
        CarouselModule,
        SwiperModule,
        SlickCarouselModule,
        // DemoMaterialModule,
        MatNativeDateModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        // ReactiveFormsModule,
        MatDatepickerModule,



    ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
      {
          provide: SWIPER_CONFIG,
          useValue: DEFAULT_SWIPER_CONFIG
      },
      ThemeService,

  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
