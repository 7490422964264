import { PAYMENT_CONFIG } from "../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./loader.service";
var PaymentService = /** @class */ (function () {
    function PaymentService(http, loaderService) {
        this.http = http;
        this.loaderService = loaderService;
        this.config = PAYMENT_CONFIG;
    }
    PaymentService.prototype.setCashAppShortName = function (name) {
        sessionStorage.setItem('cashAppShortName', name);
    };
    PaymentService.prototype.getCashAppShortName = function () {
        return sessionStorage.getItem('cashAppShortName');
    };
    PaymentService.prototype.resetCashAppShortName = function () {
        sessionStorage.removeItem('cashAppShortName');
    };
    PaymentService.prototype.getCartInfo = function (shortName, amount) {
        this.loaderService.show();
        return this.http.post(this.config.getCartInfo, { shortName: shortName, amount: amount });
    };
    PaymentService.prototype.buyWithCashApp = function (shortName, amount, token) {
        this.loaderService.show();
        return this.http.post(this.config.buyWithCashApp, { shortName: shortName, amount: amount, token: token });
    };
    PaymentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PaymentService_Factory() { return new PaymentService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: PaymentService, providedIn: "root" });
    return PaymentService;
}());
export { PaymentService };
