import {DOMAIN_API} from "./local_settings";
import {DOMAIN_DEMO_API,DOMAIN_DEMO_NAME} from "./demo_settings";
import {AuthConfig} from '../modules/auth/_services/auth-config';
import {environment} from "../../environments/environment";

let domainApiUrl = DOMAIN_API;
if(window.location.hostname == DOMAIN_DEMO_NAME){
    domainApiUrl = DOMAIN_DEMO_API;
}

let API = domainApiUrl;
let API_SENETPRO = API + '/senetpro';

if (environment.frontEndTest) {
    API = DOMAIN_API  + '/client';
    API_SENETPRO = DOMAIN_API  + '/client';
}

const USERS = API_SENETPRO + '/users';
const TERMINALS = API_SENETPRO + '/terminals';
const REDEEM = TERMINALS + '/redeem';
const DEPOSIT = TERMINALS + '/deposit';
const CHECKOUT = API_SENETPRO + '/checkout';

export const AUTH_CONFIG: AuthConfig = {
    checkPhoneAndSendPin: USERS + '/phone/check',
    register: USERS + '/register/terminal',
    login: USERS + '/session/login',
    logout: USERS + '/session/logout',
    refresh: USERS + '/session/refresh'
};

export const APP_VERSION_CONFIG = {
    version: TERMINALS +'/version',
};

export const USER_BALANCE_CONFIG = {
    getDepositBalance: DEPOSIT,
    onDeposit: DEPOSIT + '/create',
    getRedeemBalance: REDEEM,
    onRedeem: REDEEM + '/create'
}
if (environment.frontEndTest) {
    USER_BALANCE_CONFIG.getDepositBalance = API + '/jsons/find/file?name=senetTerminalGetDepositBalance';
    USER_BALANCE_CONFIG.onDeposit = API + '/jsons/find/file?name=success';
    USER_BALANCE_CONFIG.getRedeemBalance = API + '/jsons/find/file?name=senetTerminalGetRedeemBalance';
    USER_BALANCE_CONFIG.onRedeem = API + '/jsons/find/file?name=error';
}

export const GAMES_SENET_CONFIG = {
    getSenetSession: API + '/games/senet/session/terminal',
    // getSenetSession: API + '/games/senet/session/create',
}
if (environment.frontEndTest) {
    GAMES_SENET_CONFIG.getSenetSession = API + '/jsons/find/file?name=getSenetSession';
}

export const TERMINAL_CONFIG = {
    checkActivity: TERMINALS + '/activity',
    updatesLast: TERMINALS + '/updates/last',
    getSettings: TERMINALS + '/settings',
    registerTerminal: TERMINALS + '/create',
};
if (environment.frontEndTest) {
    TERMINAL_CONFIG.checkActivity = API + '/jsons/find/file?name=success';
    TERMINAL_CONFIG.updatesLast = API + '/jsons/find/file?name=updates';
    TERMINAL_CONFIG.registerTerminal = API + '/jsons/find/file?name=success';
    TERMINAL_CONFIG.getSettings = API + '/jsons/find/file?name=senetTerminalGetSettings';
}

export const PAYMENT_CONFIG = {
    getCartInfo: CHECKOUT + '/payment/cart',
    buyWithCashApp: CHECKOUT + '/payment/cashapp'

};
if (environment.frontEndTest) {
    PAYMENT_CONFIG.getCartInfo = API_SENETPRO + '/jsons/find/file?name=checkoutBuyCart';
    PAYMENT_CONFIG.buyWithCashApp = API_SENETPRO + '/jsons/find/file?name=success';
}
