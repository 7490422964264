import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ButtonComponent} from "./button/button.component";

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import {PopupComponent} from "./popup/popup.component";
import {ReactiveFormsModule} from "@angular/forms";
import {NgxMaskModule} from "ngx-mask";
import {LoaderComponent} from "./loader/loader.component";
import {KeyboardComponent} from "./keyboard/keyboard.component";
import {PopupBalanceComponent} from "./popup-balance/popup-balance.component";
import {InvestmentsNftPopupComponent} from "./investments-nft-popup/investments-nft-popup.component";
import {ScreenSaverComponent} from "./screen-saver/screen-saver.component";


export const APP_DATE_FORMATS = {
    parse: {dateInput: {month: 'short', year: 'numeric', day: 'numeric'}},
    display: {
        dateInput: {month: 'numeric', year: 'numeric', day: 'numeric'},
        yearLabel: {year: 'numeric'},
        monthLabel: {month: 'short'}
    }
};

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};


@NgModule({
    declarations: [
        ButtonComponent,
        PopupComponent,
        LoaderComponent,
        KeyboardComponent,
        PopupBalanceComponent,
        InvestmentsNftPopupComponent,
        ScreenSaverComponent
    ],
    imports: [
        CommonModule,
        PerfectScrollbarModule,
        ReactiveFormsModule,
        NgxMaskModule.forRoot(),
    ],
    providers: [
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
    ],
    entryComponents: [
        PopupBalanceComponent
    ],
    exports: [
        ButtonComponent,
        PopupComponent,
        LoaderComponent,
        KeyboardComponent,
        PopupBalanceComponent,
        InvestmentsNftPopupComponent,
        ScreenSaverComponent
    ]
})
export class SharedModule { }
