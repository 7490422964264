import { APP_VERSION_CONFIG } from "../../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
import * as i3 from "../../shared/popup/popup.component";
var AppVersionrService = /** @class */ (function () {
    function AppVersionrService(http, router, popupComponent) {
        this.http = http;
        this.router = router;
        this.popupComponent = popupComponent;
        this.config = APP_VERSION_CONFIG;
    }
    AppVersionrService.prototype.setVersionCount = function (count) {
        localStorage.setItem('versionCount', count);
    };
    AppVersionrService.prototype.getVersionCount = function () {
        if (localStorage.getItem('versionCount')) {
            return parseInt(localStorage.getItem('versionCount'));
        }
        return 0;
    };
    AppVersionrService.prototype.checkVersion = function () {
        var _this = this;
        this.getVersion().subscribe(function (data) {
            var version = _this.getMeta('app-version');
            if (version != data.result) {
                var versionCount = _this.getVersionCount();
                ++versionCount;
                _this.setVersionCount(versionCount);
                _this.showUpgradePopup();
            }
            else {
                _this.setVersionCount(0);
            }
        });
        var $this = this;
        setTimeout(function () {
            $this.checkVersion();
        }, 5 * 60 * 1000);
    };
    AppVersionrService.prototype.showUpgradePopup = function () {
        var _this = this;
        if (this.getVersionCount() > 1) {
            this.popupComponent.showPopupAgreement({
                text: 'Please restart the application',
                closeBtn: false,
                showConfirmButton: false
            }, function () {
            });
        }
        else {
            this.popupComponent.showPopupAgreement({
                text: 'Version on your device is old, please update it now',
                closeBtn: false,
                confirmButtonText: 'Upgrade'
            }, function () {
                _this.upgradeApp();
            });
        }
    };
    AppVersionrService.prototype.getVersion = function () {
        return this.http.post(this.config.version, {});
    };
    AppVersionrService.prototype.getMeta = function (metaName) {
        var metas = document.getElementsByTagName('meta');
        for (var i = 0; i < metas.length; i++) {
            if (metas[i].getAttribute('name') === metaName) {
                return metas[i].getAttribute('content');
            }
        }
        return '';
    };
    AppVersionrService.prototype.upgradeApp = function () {
        var time = +new Date();
        window.location.href = window.location.protocol + '//' + this.getDomain(window.location.href, true) + '?hash=' + time;
    };
    AppVersionrService.prototype.getDomain = function (url, subdomain) {
        subdomain = subdomain || false;
        url = url.replace(/(https?:\/\/)?(www.)?/i, '');
        if (!subdomain) {
            url = url.split('.');
            url = url.slice(url.length - 2).join('.');
        }
        if (url.indexOf('/') !== -1) {
            return url.split('/')[0];
        }
        return url;
    };
    AppVersionrService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppVersionrService_Factory() { return new AppVersionrService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.PopupComponent)); }, token: AppVersionrService, providedIn: "root" });
    return AppVersionrService;
}());
export { AppVersionrService };
