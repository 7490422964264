import { ApplicationRef, ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, Injector } from '@angular/core';
import { Subject, merge, fromEvent, from, interval, timer, of } from 'rxjs';
import { bufferTime, distinctUntilChanged, filter, finalize, map, scan, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { UserIdleComponent } from './user-idle/user-idle.component';
import * as i0 from "@angular/core";
import * as i1 from "./user-idle-config";
/**
 * User's idle service.
 */
var UserIdleService = /** @class */ (function () {
    function UserIdleService(config, componentFactoryResolver, appRef, injector) {
        this.config = config;
        this.componentFactoryResolver = componentFactoryResolver;
        this.appRef = appRef;
        this.injector = injector;
        this.timerStart$ = new Subject();
        this.timeout$ = new Subject();
        /**
         * Idle value in seconds.
         * Default equals to 10 minutes.
         */
        this.idle = 600;
        /**
         * Timeout value in seconds.
         * Default equals to 5 minutes.
         */
        this.timeout = 300;
        /**
         * Ping value in seconds.
         * * Default equals to 2 minutes.
         */
        this.ping = 120;
        /**
         *  if idle can show dialog
         */
        this.showIdleDialog = false;
        if (config) {
            //console.log(this.config);
            this.idle = config.idle;
            this.timeout = config.timeout;
            this.ping = config.ping;
            this.showIdleDialog = config.showIdleDialog;
        }
        this.activityEvents$ = merge(fromEvent(window, 'mousemove'), fromEvent(window, 'resize'), fromEvent(document, 'keydown'), fromEvent(window, 'click'), fromEvent(window, 'blur'));
        this.idle$ = from(this.activityEvents$);
    }
    /**
     * Start watching for user idle and setup timer and ping.
     */
    UserIdleService.prototype.startWatching = function () {
        var _this = this;
        if (this.idle === 0) {
            return;
        }
        if (this.idleSubscription) {
            this.idleSubscription.unsubscribe();
        }
        // If any of user events is not enabled for idle-seconds when start timer.
        this.idleSubscription = this.idle$
            .pipe(bufferTime(2000), // Starting point of detecting of user's inactivity
        filter(function (arr) {
            if (!!arr.length && !_this.isInactivityTimer) {
                _this.resetTimer();
            }
            return !arr.length && !_this.isInactivityTimer;
        }), tap(function () { return _this.isInactivityTimer = true; }), switchMap(function () { return interval(1000).pipe(takeUntil(merge(_this.activityEvents$, timer(_this.idle * 1000).pipe(tap(function () { return _this.timerStart$.next(true); })))), finalize(function () {
            _this.isInactivityTimer = false;
            ////console.log(this.isInactivityTimer);
        })); })).subscribe();
        this.setupTimer(this.timeout);
        this.setupPing(this.ping);
    };
    UserIdleService.prototype.stopWatching = function () {
        if (this.idle === 0) {
            return;
        }
        this.stopTimer();
        if (this.idleSubscription) {
            this.idleSubscription.unsubscribe();
        }
    };
    UserIdleService.prototype.stopTimer = function () {
        if (this.idle === 0) {
            return;
        }
        this.timerStart$.next(false);
        if (this.comp && this.comp != null) {
            this.destroy(this.comp.hostView);
        }
    };
    UserIdleService.prototype.resetTimer = function () {
        if (this.idle === 0) {
            return;
        }
        this.stopTimer();
        this.isTimeout = false;
    };
    /**
     * Return observable for timer's countdown number that emits after idle.
     */
    UserIdleService.prototype.onTimerStart = function () {
        var _this = this;
        return this.timerStart$.pipe(distinctUntilChanged(), switchMap(function (start) { return (start ? _this.timer$ : of(null)); }), tap(function (j) {
            if (!!j && _this.showIdleDialog) {
                if (!_this.comp) {
                    _this.showDialog();
                }
                _this.comp.instance.timeToEnd = _this.timeout - j;
            }
        }));
    };
    /**
     * Return observable for timeOut is fired.
     */
    UserIdleService.prototype.onTimeout = function () {
        var _this = this;
        return this.timeout$.pipe(filter(function (timeout) { return !!timeout; }), tap(function () {
            _this.isTimeout = true;
        }), map(function () {
            return true;
        }));
    };
    UserIdleService.prototype.getConfigValue = function () {
        return {
            idle: this.idle,
            timeout: this.timeout,
            ping: this.ping,
            showIdleDialog: this.showIdleDialog
        };
    };
    /**
     * Set config values.
     * @param config
     */
    UserIdleService.prototype.setConfigValues = function (config) {
        if (this.idleSubscription && !this.idleSubscription.closed) {
            console.error('Call stopWatching() before formset config values');
            return;
        }
        if (config.idle) {
            this.idle = config.idle;
        }
        if (config.ping) {
            this.ping = config.ping;
        }
        if (config.timeout) {
            this.timeout = config.timeout;
        }
        if (config.showIdleDialog) {
            this.showIdleDialog = config.showIdleDialog;
        }
    };
    /**
     * Setup timer.
     *
     * Counts every seconds and return n+1 and fire timeOut for last count.
     * @param timeout Timeout in seconds.
     */
    UserIdleService.prototype.setupTimer = function (timeout) {
        var _this = this;
        this.timer$ = interval(30000).pipe(take(timeout), map(function () { return 1; }), scan(function (acc, n) { return acc + n; }), tap(function (count) {
            if (count === timeout) {
                _this.timeout$.next(true);
            }
        }));
    };
    /**
     * Setup ping.
     *
     * Pings every ping-seconds only if is not timeOut.
     * @param ping
     */
    UserIdleService.prototype.setupPing = function (ping) {
        var _this = this;
        this.ping$ = interval(ping * 1000).pipe(filter(function () { return !_this.isTimeout; }));
    };
    UserIdleService.prototype.destroy = function (comp) {
        this.appRef.detachView(comp);
        comp.destroy();
        this.comp = null;
    };
    UserIdleService.prototype.showDialog = function () {
        var _this = this;
        console.log(this.comp);
        this.comp = this.componentFactoryResolver
            .resolveComponentFactory(UserIdleComponent)
            .create(this.injector);
        // Attach component to the appRef so that it's inside the ng component tree
        this.appRef.attachView(this.comp.hostView);
        this.comp.instance.onCloseDialog.subscribe(function (j) {
            _this.destroy(_this.comp.hostView);
        });
        // Get DOM element from component
        var domElem = this.comp.hostView
            .rootNodes[0];
        // Append DOM element to the body
        document.body.appendChild(domElem);
    };
    UserIdleService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserIdleService_Factory() { return new UserIdleService(i0.ɵɵinject(i1.UserIdleConfig, 8), i0.ɵɵinject(i0.ComponentFactoryResolver), i0.ɵɵinject(i0.ApplicationRef), i0.ɵɵinject(i0.INJECTOR)); }, token: UserIdleService, providedIn: "root" });
    return UserIdleService;
}());
export { UserIdleService };
