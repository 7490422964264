import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PopupComponent} from "../popup/popup.component";
import {CRYPTO_AMOUNT_MASK, PROJECT_MESSAGES} from "../../config/project";
import {LoaderService} from "../../service/loader.service";
import {UserBalanceService} from "../../service/users/userBalance.service";
import {POPUP_BALANCE_INCREASE_BALANCE_TYPE, PopupRewardsGameInfoModel} from "./popup-balance.config";
import {KeyboardService} from "../../service/keyboard.service";
import {GamesSenetService} from "../../service/games/gamesSenet.service";
import {PaymentService} from "../../service/payment.service";
import {NFT_POPUP_PAYMENT_TYPE, NFT_POPUP_TYPE} from "../investments-nft-popup/investments-nft-popup.config";


@Component({
    selector: 'app-popup-balance',
    templateUrl: './popup-balance.component.html',
    styleUrls: ['./popup-balance.component.scss']
})
export class PopupBalanceComponent implements OnInit {
    @Input() popupInfo: PopupRewardsGameInfoModel = {show: false, balance: null, type: null, canClose: true};
    @Input() cashAppShortName: string = '';
    @Output() close: EventEmitter<boolean> = new EventEmitter();
    @Output() updateBalance: EventEmitter<any> = new EventEmitter();
    @Output() onGameSweep: EventEmitter<any> = new EventEmitter();

    balanceForm: FormGroup;
    moreThenAvailablePayout = false;
    POPUP_BALANCE_INCREASE_BALANCE_TYPE = POPUP_BALANCE_INCREASE_BALANCE_TYPE;

    gameVendorName = 'Senet';

    showNFTPopup = false;
    NFTPopupData;
    NFTPopupFormData;

    @ViewChild('imageInput', {static: false}) imageInput;
    @ViewChild('termsAccept', {static: false}) termsAccept: ElementRef;
    constructor(private loaderService: LoaderService,
                private userBalanceService: UserBalanceService,
                private gamesSenetService: GamesSenetService,
                private popupComponent: PopupComponent,
                private keyboardService: KeyboardService,
                private paymentService: PaymentService,
                private formBuilder: FormBuilder) {}

    ngOnInit() {
        this.cashAppShortName = this.paymentService.getCashAppShortName();
        this.balanceForm = this.formBuilder.group({
            amount: [null, Validators.required]
        });
    }

    closePopup() {
        this.close.emit(true);
    }

    onInputAmount() {
        const balance = this.popupInfo.balance;
        const avaliable = typeof balance === 'number' ? balance : Number.parseFloat(balance);
        const entered = Number.parseFloat(this.balanceForm.controls.amount.value);

        if (avaliable < entered) {
            this.balanceForm.controls.amount.setValue(String(avaliable));
            this.moreThenAvailablePayout = true;
        } else {
            this.moreThenAvailablePayout = false;
        }
    }

    onSubmitBalanceForm() {
        const amount = this.balanceForm.controls.amount.value;
        if (!amount) {
            this.popupComponent.showPopupError({text: PROJECT_MESSAGES.notAllFieldsAreFilled});
        } else {
            // const text = `Send $${formatNumber(amount, 'en', CRYPTO_AMOUNT_MASK)}.00  to ${methodName}?`;
            // this.popupComponent.showPopupConfirmCancel({text}, (status) => {
            //     if (status) {
            //
            //     }
            // });
            if (this.popupInfo.type === POPUP_BALANCE_INCREASE_BALANCE_TYPE.deposit) {
                this.userBalanceService.onDeposit(amount).subscribe(data => {
                    this.displaySubmitBalanceResult(data);
                }, (error) => {
                    this.loaderService.hideAndShowTryAgain();
                }, () => {
                    //this.loaderService.hide();
                });
            } else if (this.popupInfo.type === POPUP_BALANCE_INCREASE_BALANCE_TYPE.cashApp) {
                this.showConfirmMessage();
            } else {
                this.userBalanceService.onRedeem(amount).subscribe(data => {
                    this.displaySubmitBalanceResult(data);
                }, (error) => {
                    this.loaderService.hideAndShowTryAgain();
                }, () => {
                    //this.loaderService.hide();
                });
            }

        }
    }

    displaySubmitBalanceResult(data) {
        try {
            if(data.status){
                this.popupComponent.showPopupSuccess({text: data.message});
                this.balanceForm.controls.amount.setValue(null);
                this.moreThenAvailablePayout = false;
                this.updateBalance.emit();

                this.closePopup();

                this.sweepGame();
            }else{
                this.popupComponent.showPopupError({text: data.message});
                this.loaderService.hide();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    sweepGame() {
        this.closePopup();
        this.gamesSenetService.getSenetSessionRequest();
    }

    playGame() {
        this.closePopup();
        if (!this.popupInfo.isSessionStart) {
            this.gamesSenetService.getSenetSessionRequest();
        }
    }

    showConfirmMessage() {
        // const name = this.getPaymentName(this.activeMethod);
        const amount = this.balanceForm.controls.amount.value;
        const shortName = this.cashAppShortName;
        if (amount && shortName) {
            // this.popupComponent.showPopupAgreement({
            //       text: `${name} <br><br>NFT / <span style="text-transform: capitalize">${gameVendorName}</span>: ${formatCurrency(amount, 'en','$')}`},
            //   (status) => {
            //     if (status) {
            //         this.submit(type);
            //     }
            // });
            this.paymentService.getCartInfo(shortName, amount).subscribe(data => {
                try {
                    if (data.status) {
                        this.onShowNFTPopup(data);
                    } else {
                        this.popupComponent.showPopupError({
                            text: data.message,
                            confirmButtonText:'OK'
                        });
                    }
                } catch (e) {
                    this.loaderService.hideAndShowTryAgain();
                }
            }, (error) => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });

        } else {
            this.popupComponent.showPopupError({text: PROJECT_MESSAGES.unknownError});
        }
    }

    onShowNFTPopup(data) {
        const productType = this.cashAppShortName;
        const inputAmount = this.balanceForm.controls.amount.value ? this.balanceForm.controls.amount.value : 0;

        this.showNFTPopup = true;
        this.NFTPopupData = {
            type: productType,
            data: {
                element: data,
                payment: NFT_POPUP_PAYMENT_TYPE.cashApp,
                inputAmount: inputAmount,
            }
        };

        this.NFTPopupData.data.cashappInfo = data.cashappInfo;
        let amount = this.balanceForm.controls.amount.value ? this.balanceForm.controls.amount.value : 0;

        const form = {
            amount
        };

        this.openNFTPopup({showNFTPopup: true, data: this.NFTPopupData, form: form});
    }

    openNFTPopup($event) {
        this.showNFTPopup = $event.showNFTPopup;
        this.NFTPopupData = $event.data;
        this.NFTPopupFormData = $event.form;
    }

    onPopupNFTClose() {
        this.showNFTPopup = false;
        if (this.popupInfo.isSessionStart) {
            this.closePopup();
        }
    }

    onDepositConfirm($event) {
        const amount = $event.amount;
        const token = $event.token;
        const shortName = this.cashAppShortName;

        this.paymentService.buyWithCashApp(shortName, amount, token).subscribe(data => {
            this.displaySubmitDepositConfirm(data);
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    displaySubmitDepositConfirm(data) {
        try {
            if(data.status){
                this.popupComponent.showPopupSuccess({text: data.message});
                this.balanceForm.controls.amount.setValue(null);
                this.moreThenAvailablePayout = false;
                this.updateBalance.emit();
                this.closePopup();
                this.userBalanceService.handleIframeMessage({showPopup: POPUP_BALANCE_INCREASE_BALANCE_TYPE.deposit});
            }else{
                this.popupComponent.showPopupError({text: data.message});
                this.loaderService.hide();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }
}
