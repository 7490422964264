/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./keyboard.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./keyboard.component";
import * as i4 from "../../service/keyboard.service";
import * as i5 from "../../modules/auth/_services/authentication.service";
import * as i6 from "@angular/router";
var styles_KeyboardComponent = [i0.styles];
var RenderType_KeyboardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_KeyboardComponent, data: {} });
export { RenderType_KeyboardComponent as RenderType_KeyboardComponent };
function View_KeyboardComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, ":svg:svg", [[":xml:space", "preserve"], [":xmlns:xlink", "http://www.w3.org/1999/xlink"], ["id", "Layer_1"], ["style", "enable-background:new 0 0 512 512;"], ["version", "1.1"], ["viewBox", "0 0 512 512"], ["x", "0px"], ["xmlns", "http://www.w3.org/2000/svg"], ["y", "0px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, ":svg:path", [["d", "M445.771,238.813L264.437,4.146c-2.023-2.617-5.235-4.148-8.447-4.146c-3.205,0.003-6.409,1.534-8.428,4.146\n\t\t\tL66.229,238.813c-2.49,3.219-2.927,7.573-1.135,11.219c1.792,3.656,5.5,5.969,9.573,5.969h96v245.333\n\t\t\tc0,5.896,4.771,10.667,10.667,10.667h149.333c5.896,0,10.667-4.771,10.667-10.667V256h96c4.073,0,7.781-2.313,9.573-5.969\n\t\t\tC448.698,246.385,448.26,242.031,445.771,238.813z M330.667,234.667c-5.896,0-10.667,4.771-10.667,10.667v245.333H192V245.333\n\t\t\tc0-5.896-4.771-10.667-10.667-10.667H96.385L256,28.115l159.615,206.552H330.667z"]], null, null, null, null, null))], null, null); }
function View_KeyboardComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, ":svg:svg", [[":xml:space", "preserve"], [":xmlns:xlink", "http://www.w3.org/1999/xlink"], ["id", "Capa_1"], ["style", "enable-background:new 0 0 512 512;"], ["version", "1.1"], ["viewBox", "0 0 512 512"], ["x", "0px"], ["xmlns", "http://www.w3.org/2000/svg"], ["y", "0px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, ":svg:path", [["d", "M445.771,238.813L264.438,4.146c-2.019-2.612-5.223-4.143-8.428-4.146c-3.211-0.003-6.424,1.529-8.447,4.146    L66.229,238.813c-2.49,3.219-2.927,7.573-1.135,11.219c1.792,3.656,5.5,5.969,9.573,5.969h96v245.333    c0,5.896,4.771,10.667,10.667,10.667h149.333c5.896,0,10.667-4.771,10.667-10.667V256h96c4.073,0,7.781-2.313,9.573-5.969    C448.698,246.385,448.26,242.031,445.771,238.813z"], ["style", "\n    /* fill: aqua; */\n    /* stroke: red; */\n    /* stroke-width: 10px; */\n"]], null, null, null, null, null))], null, null); }
function View_KeyboardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 94, "div", [["class", "keyboard"]], [[2, "keyboard--caps", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 68, "div", [["class", "keyboard__left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 20, "div", [["class", "keyboard__row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["class", "keyboard__key"], ["title", "q"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["q"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [["class", "keyboard__key"], ["title", "w"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["w"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "button", [["class", "keyboard__key"], ["title", "e"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["e"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "button", [["class", "keyboard__key"], ["title", "r"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["r"])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "button", [["class", "keyboard__key"], ["title", "t"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["t"])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "button", [["class", "keyboard__key"], ["title", "y"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["y"])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "button", [["class", "keyboard__key"], ["title", "u"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["u"])), (_l()(), i1.ɵeld(17, 0, null, null, 1, "button", [["class", "keyboard__key"], ["title", "i"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["i"])), (_l()(), i1.ɵeld(19, 0, null, null, 1, "button", [["class", "keyboard__key"], ["title", "o"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["o"])), (_l()(), i1.ɵeld(21, 0, null, null, 1, "button", [["class", "keyboard__key"], ["title", "p"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["p"])), (_l()(), i1.ɵeld(23, 0, null, null, 18, "div", [["class", "keyboard__row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 1, "button", [["class", "keyboard__key"], ["title", "a"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["a"])), (_l()(), i1.ɵeld(26, 0, null, null, 1, "button", [["class", "keyboard__key"], ["title", "s"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["s"])), (_l()(), i1.ɵeld(28, 0, null, null, 1, "button", [["class", "keyboard__key"], ["title", "d"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["d"])), (_l()(), i1.ɵeld(30, 0, null, null, 1, "button", [["class", "keyboard__key"], ["title", "f"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["f"])), (_l()(), i1.ɵeld(32, 0, null, null, 1, "button", [["class", "keyboard__key"], ["title", "g"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["g"])), (_l()(), i1.ɵeld(34, 0, null, null, 1, "button", [["class", "keyboard__key"], ["title", "h"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["h"])), (_l()(), i1.ɵeld(36, 0, null, null, 1, "button", [["class", "keyboard__key"], ["title", "j"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["j"])), (_l()(), i1.ɵeld(38, 0, null, null, 1, "button", [["class", "keyboard__key"], ["title", "k"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["k"])), (_l()(), i1.ɵeld(40, 0, null, null, 1, "button", [["class", "keyboard__key"], ["title", "l"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["l"])), (_l()(), i1.ɵeld(42, 0, null, null, 16, "div", [["class", "keyboard__row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(43, 0, null, null, 1, "button", [["class", "keyboard__key"], ["title", "z"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["z"])), (_l()(), i1.ɵeld(45, 0, null, null, 1, "button", [["class", "keyboard__key"], ["title", "x"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["x"])), (_l()(), i1.ɵeld(47, 0, null, null, 1, "button", [["class", "keyboard__key"], ["title", "c"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["c"])), (_l()(), i1.ɵeld(49, 0, null, null, 1, "button", [["class", "keyboard__key"], ["title", "v"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["v"])), (_l()(), i1.ɵeld(51, 0, null, null, 1, "button", [["class", "keyboard__key"], ["title", "b"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["b"])), (_l()(), i1.ɵeld(53, 0, null, null, 1, "button", [["class", "keyboard__key"], ["title", "n"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["n"])), (_l()(), i1.ɵeld(55, 0, null, null, 1, "button", [["class", "keyboard__key"], ["title", "m"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["m"])), (_l()(), i1.ɵeld(57, 0, null, null, 1, "button", [["class", "keyboard__key keyboard__key--no-caps"], ["title", ","]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [","])), (_l()(), i1.ɵeld(59, 0, null, null, 10, "div", [["class", "keyboard__row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(60, 0, null, null, 4, "button", [["class", "keyboard__key keyboard__key--md keyboard__key--no-caps"], ["title", "up"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_KeyboardComponent_2)), i1.ɵdid(62, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_KeyboardComponent_3)), i1.ɵdid(64, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(65, 0, null, null, 1, "button", [["class", "keyboard__key keyboard__key--lg keyboard__key--no-caps"], ["title", " "]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["space"])), (_l()(), i1.ɵeld(67, 0, null, null, 2, "button", [["class", "keyboard__key keyboard__key--md keyboard__key--no-caps"], ["title", "cancel"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(68, 0, null, null, 1, ":svg:svg", [["enable-background", "new 0 0 428.235 428.235"], ["height", "512"], ["id", "Capa_1"], ["viewBox", "0 0 428.235 428.235"], ["width", "512"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(69, 0, null, null, 0, ":svg:path", [["d", "m388.088 53.529h-241.575c-12.089 0-23.419 5.358-31.091 14.728l-112.403 137.392c-4.025 4.927-4.025 12.01 0 16.937l112.417 137.404c7.658 9.357 18.989 14.715 31.077 14.715h241.575c22.138 0 40.147-18.009 40.147-40.147v-240.881c0-22.139-18.008-40.148-40.147-40.148zm-61.37 208.577-37.847 37.847-47.988-47.988-47.988 47.988-37.847-37.847 47.988-47.988-47.988-47.988 37.847-37.847 47.988 47.988 47.988-47.988 37.847 37.847-47.988 47.988c-.001 0 47.988 47.988 47.988 47.988z"]], null, null, null, null, null)), (_l()(), i1.ɵeld(70, 0, null, null, 24, "div", [["class", "keyboard__right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(71, 0, null, null, 1, "button", [["class", "keyboard__key keyboard__key--no-caps"], ["title", "1"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["1"])), (_l()(), i1.ɵeld(73, 0, null, null, 1, "button", [["class", "keyboard__key keyboard__key--no-caps"], ["title", "2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["2"])), (_l()(), i1.ɵeld(75, 0, null, null, 1, "button", [["class", "keyboard__key keyboard__key--no-caps"], ["title", "3"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["3"])), (_l()(), i1.ɵeld(77, 0, null, null, 1, "button", [["class", "keyboard__key keyboard__key--no-caps"], ["title", "4"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["4"])), (_l()(), i1.ɵeld(79, 0, null, null, 1, "button", [["class", "keyboard__key keyboard__key--no-caps"], ["title", "5"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["5"])), (_l()(), i1.ɵeld(81, 0, null, null, 1, "button", [["class", "keyboard__key keyboard__key--no-caps"], ["title", "6"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["6"])), (_l()(), i1.ɵeld(83, 0, null, null, 1, "button", [["class", "keyboard__key keyboard__key--no-caps"], ["title", "7"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["7"])), (_l()(), i1.ɵeld(85, 0, null, null, 1, "button", [["class", "keyboard__key keyboard__key--no-caps"], ["title", "8"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["8"])), (_l()(), i1.ɵeld(87, 0, null, null, 1, "button", [["class", "keyboard__key keyboard__key--no-caps"], ["title", "9"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["9"])), (_l()(), i1.ɵeld(89, 0, null, null, 1, "button", [["class", "keyboard__key keyboard__key--no-caps"], ["title", "@"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["@"])), (_l()(), i1.ɵeld(91, 0, null, null, 1, "button", [["class", "keyboard__key keyboard__key--no-caps"], ["title", "0"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["0"])), (_l()(), i1.ɵeld(93, 0, null, null, 1, "button", [["class", "keyboard__key keyboard__key--no-caps"], ["title", "."]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickHandle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["."]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.capitalLetters; _ck(_v, 62, 0, currVal_1); var currVal_2 = _co.capitalLetters; _ck(_v, 64, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.capitalLetters; _ck(_v, 0, 0, currVal_0); }); }
export function View_KeyboardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_KeyboardComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.keyboardVisible; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_KeyboardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-keyboard", [], null, null, null, View_KeyboardComponent_0, RenderType_KeyboardComponent)), i1.ɵdid(1, 114688, null, 0, i3.KeyboardComponent, [i4.KeyboardService, i5.AuthenticationService, i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var KeyboardComponentNgFactory = i1.ɵccf("app-keyboard", i3.KeyboardComponent, View_KeyboardComponent_Host_0, {}, {}, []);
export { KeyboardComponentNgFactory as KeyboardComponentNgFactory };
