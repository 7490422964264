import { BehaviorSubject } from 'rxjs';
import { NavigationEnd } from "@angular/router";
import { KEYBOARD_SETTINGS } from "../config/keyboard_settings";
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../modules/auth/_services/authentication.service";
var KeyboardService = /** @class */ (function () {
    function KeyboardService(router, activatedRoute, auth) {
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.auth = auth;
        this.inputs = document.querySelectorAll('input[type=text], input[type=tel], input[type=number]');
        this.capsLock = new BehaviorSubject(false);
        this.capsLockReset = false;
        this.activityTimer = new BehaviorSubject(0);
        this.wasLogout = new BehaviorSubject(false);
        this.keyboardVisible = new BehaviorSubject(false);
        this.isApp = +localStorage.getItem('isApp');
        this.smallInputValueChanged = new BehaviorSubject({ order: 0, value: null });
        this.isSearch = false;
        this.isKeyboardVisibleKey = 'keyboardIsVisible';
        this.isKeyboardVisible = new BehaviorSubject(false);
    }
    KeyboardService.prototype.init = function () {
        var _this = this;
        setTimeout(function () {
            // console.log("test_test: 5");
            _this.setActiveInput();
        }, 1000);
        this.router.events.subscribe(function (event) {
            if (event instanceof NavigationEnd) {
                setTimeout(function () {
                    // console.log("test_test: 6");
                    _this.setActiveInput();
                }, 1000);
            }
        });
    };
    KeyboardService.prototype.setActiveInput = function () {
        var _this = this;
        this.inputs = document.querySelectorAll(KEYBOARD_SETTINGS.excludedInputs);
        if (this.inputs.length) {
            this.activeInput = this.inputs[0];
            this.inputs.forEach(function (el) {
                el.addEventListener('focus', function () {
                    _this.activeInput = el;
                });
                el.scrollLeft = el.scrollWidth;
            });
            if (!this.wasLogout.value) {
                this.activeInput.focus();
                this.showKeyboard();
            }
        }
    };
    KeyboardService.prototype.showKeyboard = function () {
        if (this.isKeyboardVisible.value) {
            this.keyboardVisible.next(true);
        }
    };
    KeyboardService.prototype.hideKeyboard = function () {
        this.keyboardVisible.next(false);
    };
    KeyboardService.prototype.onInput = function (value) {
        var val = this.capsLock.value ? value.toUpperCase() : value.toLowerCase();
        var cursorPosition = this.activeInput.selectionStart ? this.activeInput.selectionStart : 0;
        this.activeInput.focus();
        if (this.isSearch) {
            this.win = document.getElementById("iframe")['contentWindow'];
            this.win.postMessage({ focus: true }, "*");
            cursorPosition = this.searchCursorPosition;
        }
        if (this.capsLockReset && value !== 'up') {
            this.capsLock.next(false);
            this.capsLockReset = false;
        }
        if (value === 'up') {
            this.capsLock.next(!this.capsLock.value);
            this.capsLockReset = true;
            return;
        }
        if (this.activeInput.classList.contains('login-input-small') && !this.isSearch) {
            var inputOrder = +document.activeElement.getAttribute('data-order');
            var countAllInputs = document.querySelectorAll('.login-input-small').length - 1;
            if (value === 'cancel') {
                document.activeElement.value = '';
                this.smallInputValueChanged.next({ order: +inputOrder, value: null });
                if (+inputOrder !== 0) {
                    this.setPrevSmallInput(inputOrder);
                }
            }
            else if (Number(value) || value === "0") {
                if (document.activeElement.value) {
                    if (+inputOrder !== countAllInputs) {
                        this.setNextSmallInput(inputOrder);
                        inputOrder += 1;
                    }
                }
                document.activeElement.value = value;
                this.smallInputValueChanged.next({ order: inputOrder, value: value });
                if (+inputOrder !== countAllInputs) {
                    this.setNextSmallInput(inputOrder);
                }
            }
            return;
        }
        if (value === 'cancel') {
            cursorPosition -= 1;
            var str = document.activeElement.value;
            document.activeElement.value = this.removeCharacter(str, cursorPosition);
            document.activeElement.dispatchEvent(new Event('input'));
            // cursorPosition -= 1;
            this.activeInput.setSelectionRange(cursorPosition, cursorPosition);
            if (this.isSearch) {
                var str_1 = this.searchValue;
                var newVal = this.removeCharacter(str_1, cursorPosition);
                this.win.postMessage({ onInput: true, value: newVal, cursorPosition: cursorPosition }, "*");
            }
            return;
        }
        var oldValue = document.activeElement.value;
        var newValue = oldValue.slice(0, cursorPosition) + val + oldValue.slice(cursorPosition);
        document.activeElement.value = newValue;
        document.activeElement.dispatchEvent(new Event('input', { bubbles: true }));
        var index = this.activeInput.value.indexOf(val, [cursorPosition]);
        if (index > -1) {
            cursorPosition = index + 1;
        }
        var inputType = this.activeInput.getAttribute('type');
        if (inputType === 'email') {
            this.activeInput.setAttribute('type', 'text');
        }
        this.activeInput.setSelectionRange(cursorPosition, cursorPosition);
        if (this.isSearch) {
            var str = this.searchValue;
            var newVal = str.slice(0, cursorPosition) + val + str.slice(cursorPosition);
            this.searchValue = newVal;
            var index_1 = this.searchValue.indexOf(val, [cursorPosition]);
            if (index_1 > -1) {
                cursorPosition = index_1 + 1;
            }
            this.win.postMessage({ onInput: true, value: newVal, cursorPosition: cursorPosition }, "*");
        }
    };
    KeyboardService.prototype.setPrevSmallInput = function (inputOrder) {
        var prevInput;
        this.inputs.forEach(function (el) {
            if (el.getAttribute('data-order') === String(+inputOrder - 1)) {
                prevInput = el;
            }
        });
        this.activeInput = prevInput;
        this.activeInput.focus();
    };
    KeyboardService.prototype.setNextSmallInput = function (inputOrder) {
        var nextInput;
        this.inputs.forEach(function (el) {
            if (el.getAttribute('data-order') === String(+inputOrder + 1)) {
                nextInput = el;
            }
        });
        this.activeInput = nextInput;
        this.activeInput.focus();
    };
    KeyboardService.prototype.onFocusChanged = function (el) {
        this.activeInput = el;
        this.activeInput.focus();
    };
    KeyboardService.prototype.setIsKeyboardVisible = function (val) {
        // const isKeyboardVisible = val && (val !== '0') ? '1' : '0';
        // sessionStorage.setItem(this.isKeyboardVisibleKey, isKeyboardVisible);
        var isKeyboardVisible = val && (val !== '0') ? 1 : 0;
        this.isKeyboardVisible.next(!!isKeyboardVisible);
        if (!!isKeyboardVisible) {
            document.body.classList.remove('no-keyboard');
        }
        else {
            document.body.classList.add('no-keyboard');
        }
    };
    KeyboardService.prototype.getIsKeyboardVisible = function (val) {
        return !+sessionStorage.getItem(this.isKeyboardVisibleKey);
    };
    KeyboardService.prototype.removeCharacter = function (str, char_pos) {
        var part1 = str.substring(0, char_pos);
        var part2 = str.substring(char_pos + 1, str.length);
        return (part1 + part2);
    };
    KeyboardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function KeyboardService_Factory() { return new KeyboardService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i1.ActivatedRoute), i0.ɵɵinject(i2.AuthenticationService)); }, token: KeyboardService, providedIn: "root" });
    return KeyboardService;
}());
export { KeyboardService };
