import {HostListener, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {LoaderService} from "../loader.service";
import {PopupComponent} from "../../shared/popup/popup.component";
import {GAMES_SENET_CONFIG} from "../../config/api";
import {AuthenticationService} from "../../modules/auth/_services/authentication.service";


@Injectable({
    providedIn: 'root'
})
export class GamesSenetService {
    private config = GAMES_SENET_CONFIG;

    constructor(private popupComponent: PopupComponent,
                private http: HttpClient,
                private authenticationService: AuthenticationService,
                public loaderService: LoaderService) {
    }

    getSenetSession() {
        this.loaderService.show();
        let post = this.http.post<any>(this.config.getSenetSession, {});
        return post;
    }

    getSenetSessionRequest() {
        this.getSenetSession().subscribe(data => {
            this.setSenetSession(data);
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            // this.loaderService.hide();
        });
    }

    setSenetSession(data): void {
        try {
            if (data.status) {
                const url = data.sessionLink;
                this.openSentFrame(url);
                setTimeout(() => {
                    this.loaderService.hide();
                }, 3000)
            } else {
                this.authenticationService.logout();
                this.popupComponent.showPopupError({text: data.message});
                this.loaderService.hide();
            }
        } catch (e) {
            this.authenticationService.logout();
            this.loaderService.hideAndShowTryAgain();
        }
    }

    openSentFrame(url: string): void {
        const iframeWrapper = document.querySelector('.senet-iframe');
        const iframe = document.querySelector('.senet-iframe iframe');

        iframeWrapper.classList.add('is-active');
        // (iframe as HTMLIFrameElement).onload = this.onSenetFrameLoaded.bind(this);
        (iframe as HTMLIFrameElement).src = url;
        // (document.querySelector('app-root') as HTMLElement).classList.add('hide-menu');
    }

    // onSenetFrameLoaded() {
    //     // if (event.target.src) {
    //     const title = 'Senet';
    //     this.loaderService.hide();
    //     this.siteInfo.title = document.title;
    //     if (document.querySelector('meta[name="description"]')) {
    //         this.siteInfo.description = document.querySelector('meta[name="description"]').getAttribute("content");
    //         document.querySelector('meta[name="description"]').setAttribute("content", title);
    //     }
    //     document.title = title;
    //     this.changeThumbnailIcons(true);
    //     // }
    // }
    //
    // changeThumbnailIcons(isSenet = false) {
    //     this.changeThumbnailIcon('link[rel="icon"]', 'icon-72.png', 72, isSenet);
    //     this.changeThumbnailIcon('link[rel="apple-touch-icon"][sizes="180x180"]', 'icon-180.png', 180, isSenet);
    //     this.changeThumbnailIcon('link[rel="apple-touch-icon"][sizes="120x120"]', 'icon-120.png', 120, isSenet);
    // }
    //
    // changeThumbnailIcon(selector, icon, size: number, isSenet = false) {
    //     const defaultIcon = document.querySelector(selector).getAttribute('href');
    //     let newIcon;
    //     if (isSenet) {
    //         this.siteInfo.favicon[size] = defaultIcon;
    //         newIcon = defaultIcon.split('/icons')[0] + `/partners/senet/icons/${icon}`;
    //     } else {
    //         newIcon = this.siteInfo.favicon[size];
    //     }
    //     document.querySelector(selector).setAttribute('href', newIcon);
    // }
}
