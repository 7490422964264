import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    let storage = sessionStorage;
    if (storage.getItem('currentUser')) {
      return true;
    }

    // not logged in so redirect to login page with the return url
    //this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
    this.router.navigate(['/'], {queryParams: {returnUrl: state.url, notLogged: true}});

    return false;
  }
}
