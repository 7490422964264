import {Component, HostListener, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {LoginService} from "./service/login.service";
import {NavigationEnd, NavigationStart, Router} from "@angular/router";

import {InstallService} from "./service/install.service";
import {AuthenticationService} from "./modules/auth/_services/authentication.service";
import {ConnectionService} from "ng-connection-service";
import {PopupComponent} from "./shared/popup/popup.component";
import {AppVersionrService} from "./service/app-version/appVersionr.service";
import {TerminalService} from "./service/terminal.service";
import {UserBalanceService} from "./service/users/userBalance.service";
import {KeyboardService} from "./service/keyboard.service";
import {LoaderService} from "./service/loader.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    login = false;
    isConnected = true;
    noInternetConnection = false;
    isApp = this.terminalService.isTerminal();

    @ViewChild("popupGenerate", { read: ViewContainerRef, static: false }) popupGenerateContainer: ViewContainerRef;

    @HostListener('window:message', ['$event'])
    onMessage(event) {
        if (event.data.showPopup) {
            this.userBalanceService.handleIframeMessage(event.data);
        }

        if (this.terminalService.isTerminal()) {
            if (event.data.userActivity) {
                this.terminalService.detectUserActivity();
            }
            if (event.data.play || (event.data.play === 0)) {
                if (event.data.play) {
                    this.terminalService.isUserPlaying = true;
                } else {
                    this.terminalService.isUserPlaying = false;
                }
            }
        }

        if (event.data.showKeyboard || (event.data.showKeyboard === 0)) {
            if (event.data.showKeyboard) {
                this.keyboardService.isSearch = true;
                this.keyboardService.searchCursorPosition = event.data.searchCursorPosition;
                this.keyboardService.searchValue = event.data.searchValue;
                this.keyboardService.showKeyboard();
            } else {
                this.keyboardService.searchCursorPosition = null;
                this.keyboardService.searchValue = '';
                this.keyboardService.isSearch = false;
                this.keyboardService.hideKeyboard();
            }
        }

        if (event.data.imagesLoaded) {
            this.loaderService.hide();
        }
    }
    constructor(private router: Router,
                private installService: InstallService,
                private connectionService: ConnectionService,
                private authenticationService: AuthenticationService,
                private popupComponent: PopupComponent,
                private appVersionService: AppVersionrService,
                private userBalanceService: UserBalanceService,
                private terminalService: TerminalService,
                private keyboardService: KeyboardService,
                private loaderService: LoaderService,
                private loginService: LoginService) {
        this.connectionService.monitor().subscribe(isConnected => {
            this.isConnected = isConnected;
            if (this.isConnected) {
                this.noInternetConnection = false;
                this.popupComponent.closePopup();
            } else {
                this.noInternetConnection = true;
                this.popupComponent.showPopupError({text: 'No Internet Connection: please, try to connect to the Internet', closeBtn: false})
            }
        });

        this.userBalanceService.showModal.subscribe(val => {
            if (val.show) {
                const balance = val.balance;
                const type = val.type;
                const canClose = val.canClose;
                const isSessionStart = val.isSessionStart;
                this.userBalanceService.showGeneratePopup(this.popupGenerateContainer, balance, type, canClose, isSessionStart);
            }
        });
    }

    ngOnInit() {
        this.getSettings();
        this.terminalService.isAppDevice();
        this.loginService.loginChanged.subscribe((value: any) => this.loginChanged(value));

        this.refreshToken();
        this.appVersion();

        this.setAppHeight();
        this.setIosCSSClass();
        // this.setCorrectThumbnailIcon();

        setTimeout(() => {
            this.isApp = this.terminalService.isTerminal();
            if (this.isApp) {
                this.terminalActivity();
            }
        });
    }

    ngOnDestroy() {}

    getSettings() {
        this.terminalService.setSettings();
    }

    refreshToken(){
        setTimeout(() => {
            this.authenticationService.refreshToken();
        },60*5*1000);
    }

    terminalActivity(){
        // setTimeout(() => {
            this.terminalService.checkActivity();
        // },60*10*1000);
    }

    loginChanged(status){
        this.login = status;
    }

    appVersion(){
        this.appVersionService.checkVersion();
    }

    appHeight() {
        const doc = document.documentElement;
        doc.style.setProperty('--app-height', `${window.innerHeight}px`);
        doc.style.setProperty('--app-width', `${window.innerWidth}px`)
    }

    setAppHeight() {
        window.addEventListener('resize', this.appHeight);
        this.appHeight()
    }

    setCorrectThumbnailIcon() {
        this.changeThumbnailIcon('link[rel="icon"]', 'icon-72x72.png');
        this.changeThumbnailIcon('link[rel="apple-touch-icon"][sizes="180x180"]', 'icon-180x180.png');
        this.changeThumbnailIcon('link[rel="apple-touch-icon"][sizes="120x120"]', 'icon-120x120.png');

        this.changeTitle();
    }

    changeThumbnailIcon(selector, icon) {
        const defaultIcon = document.querySelector(selector).getAttribute('href');
        const newIcon = defaultIcon.split('/icons')[0] + `/images/partners/riverPay/${icon}`;
        document.querySelector(selector).setAttribute('href', newIcon);
    }

  changeTitle() {
    // const isRiverPay = this.partnerService.isRiverPay();
    // const partner = isRiverPay ? PARTNERS_INFO.riverPay.name : PARTNERS_INFO.default.name;
    //
    // document.querySelector("title").innerHTML = `${partner} POS`
  }

    iOS() {
        return [
              'iPad Simulator',
              'iPhone Simulator',
              'iPod Simulator',
              'iPad',
              'iPhone',
              'iPod'
          ].includes(navigator.platform)
          // iPad on iOS 13 detection
          || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }

    setIosCSSClass() {
        this.iOS() ? document.body.classList.add('is-ios') : document.body.classList.remove('is-ios');
    }
}


