import * as tslib_1 from "tslib";
import { NativeDateAdapter } from '@angular/material';
var CustomDateAdapter = /** @class */ (function (_super) {
    tslib_1.__extends(CustomDateAdapter, _super);
    function CustomDateAdapter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CustomDateAdapter.prototype.getFirstDayOfWeek = function () {
        return 1;
    };
    return CustomDateAdapter;
}(NativeDateAdapter));
export { CustomDateAdapter };
