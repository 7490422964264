import {Inject, Injectable, NgZone} from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class RememberUserService {

    constructor() {}

    checkSaveUser(userNameControl, passwordControl) {
        if (localStorage.usrname) {
            userNameControl.setValue(localStorage.usrname);
        }
        if (localStorage.password) {
            passwordControl.setValue(localStorage.password);
        }
    }

    setSaveUser(userName, password) {
        localStorage.usrname = userName;
        localStorage.password = password;
    }
}
