import { OnInit } from '@angular/core';
import * as i0 from "@angular/core";
import * as i1 from "../../service/loader.service";
var LoaderComponent = /** @class */ (function () {
    function LoaderComponent(loaderService) {
        var _this = this;
        this.loaderService = loaderService;
        this.visible = false;
        this.loaderService.visible.subscribe(function (value) { return _this.loaderChanged(value); });
    }
    LoaderComponent.prototype.ngOnInit = function () {
    };
    LoaderComponent.prototype.loaderChanged = function (value) {
        this.visible = value;
    };
    LoaderComponent.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoaderComponent_Factory() { return new LoaderComponent(i0.ɵɵinject(i1.LoaderService)); }, token: LoaderComponent, providedIn: "root" });
    return LoaderComponent;
}());
export { LoaderComponent };
