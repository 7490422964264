import { ElementRef, OnInit, QueryList } from '@angular/core';
import { Validators } from "@angular/forms";
import { PHONE_MASK } from "../../config/country_phone_mask";
import { AUTH_CODES } from "../../config/auth_codes";
import { POPUP_BALANCE_INCREASE_BALANCE_TYPE } from "../../shared/popup-balance/popup-balance.config";
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "../../modules/auth/_services/authentication.service";
import * as i3 from "../../service/login.service";
import * as i4 from "@angular/router";
import * as i5 from "../../service/loader.service";
import * as i6 from "../../service/keyboard.service";
import * as i7 from "../../service/rememberUser.service";
import * as i8 from "../../service/users/userBalance.service";
import * as i9 from "../../service/payment.service";
import * as i10 from "../../service/games/gamesSenet.service";
import * as i11 from "../../service/terminal.service";
import * as i12 from "../../service/screen-saver.service";
import * as i13 from "../../shared/popup/popup.component";
var LoginComponent = /** @class */ (function () {
    function LoginComponent(formBuilder, authenticationService, loginService, router, auth, loaderService, keyboardService, rememberUserService, userBalanceService, paymentService, gamesSenetService, terminalService, screenSaverService, popupComponent) {
        var _this = this;
        this.formBuilder = formBuilder;
        this.authenticationService = authenticationService;
        this.loginService = loginService;
        this.router = router;
        this.auth = auth;
        this.loaderService = loaderService;
        this.keyboardService = keyboardService;
        this.rememberUserService = rememberUserService;
        this.userBalanceService = userBalanceService;
        this.paymentService = paymentService;
        this.gamesSenetService = gamesSenetService;
        this.terminalService = terminalService;
        this.screenSaverService = screenSaverService;
        this.popupComponent = popupComponent;
        this.isTerminal = !!Number(localStorage.getItem('isApp'));
        this.numOfDigits = 4;
        this.country = 'us';
        this.phoneMask = PHONE_MASK[this.country];
        this.showPhonePopup = false;
        this.showPinPopup = false;
        this.showRegistrationPopup = false;
        this.showTerminalConnecting = false;
        this.logoutAfterMin = 2;
        this.logoutAfterSec = this.logoutAfterMin * 60;
        this.logoutAutoConfirmAfterSec = 20;
        this.isPromoCodeRequire = false;
        this.isKeyboard = false;
        if (this.terminalService.isTerminal()) {
            this.detectActivity();
            this.setLogoutTimer();
            this.screenSaverService.setSaveScreenTimer();
            this.terminalService.isUserActivityIframe.subscribe(function (val) {
                if (val) {
                    _this.setLogoutTimer();
                }
            });
            // this.screenSaverService.logoutCmd.subscribe(val => {
            //     if (val) {
            //         this.logoutSinglePage();
            //     }
            // })
        }
        this.keyboardService.isKeyboardVisible.subscribe(function (value) {
            _this.isKeyboard = value;
            if (value) {
                if (_this.pinForm.controls.digits.controls.length) {
                    _this.keyboardService.smallInputValueChanged.subscribe(function (settings) {
                        _this.pinForm.controls.digits.controls[settings.order].patchValue(settings.value);
                    });
                }
            }
        });
        this.authenticationService.userLoggedStatusChanged.subscribe(function (val) {
            if (val) {
            }
            else {
                _this.showPhonePopup = true;
                _this.phoneForm = _this.formBuilder.group({
                    phone: [null, Validators.required],
                });
                _this.terminalForm = _this.formBuilder.group({
                    locationId: [null, Validators.required]
                });
                _this.pinForm = _this.formBuilder.group({
                    digits: _this.formBuilder.array([]),
                    promoCode: [null],
                });
                _this.userBalanceService.closePopup();
                _this.resetPinInput();
                if (_this.terminalService.isTerminal()) {
                    _this.setLogoutTimer();
                    _this.screenSaverService.setSaveScreenTimer();
                }
                if (_this.isKeyboard) {
                    _this.keyboardService.showKeyboard();
                }
                setTimeout(function () {
                    // this.inputs.toArray()[0].nativeElement.focus();
                    document.querySelector('input').focus();
                    if (_this.isKeyboard) {
                        _this.keyboardService.setActiveInput();
                    }
                }, 0);
            }
        });
    }
    LoginComponent.prototype.ngOnInit = function () {
        this.isTerminal = this.terminalService.isTerminal();
        this.auth.logout();
        this.showPhonePopup = true;
        this.phoneForm = this.formBuilder.group({
            phone: [null, Validators.required]
        });
        this.terminalForm = this.formBuilder.group({
            locationId: [null, Validators.required]
        });
        this.pinForm = this.formBuilder.group({
            digits: this.formBuilder.array([]),
            promoCode: [null],
        });
        for (var i = 0; i < this.numOfDigits; i++) {
            this.pinForm.get('digits').push(this.formBuilder.control(null, Validators.required));
        }
    };
    LoginComponent.prototype.ngOnDestroy = function () {
        if (this.terminalService.isTerminal()) {
            this.screenSaverService.clearSaveScreenTimer();
            if (this.logoutTimeout) {
                clearTimeout(this.logoutTimeout);
            }
        }
    };
    LoginComponent.prototype.detectActivity = function () {
        var _this = this;
        setTimeout(function () {
            document.addEventListener('scroll', _this.onUserActivity.bind(_this));
            document.addEventListener('touchstart', _this.onUserActivity.bind(_this));
            document.addEventListener('click', _this.onUserActivity.bind(_this));
            document.addEventListener('keydown', _this.onUserActivity.bind(_this));
        });
    };
    LoginComponent.prototype.onUserActivity = function () {
        var _this = this;
        this.setLogoutTimer();
        if (this.showPhonePopup) {
            this.screenSaverService.hideScreenSaver();
            setTimeout(function () {
                document.querySelector('input').focus();
                if (_this.isKeyboard) {
                    _this.keyboardService.setActiveInput();
                }
            }, 0);
        }
        else {
            this.screenSaverService.clearSaveScreenTimer();
        }
    };
    LoginComponent.prototype.setLogoutTimer = function () {
        var _this = this;
        if (this.terminalService.isTerminal()) {
            if (this.logoutTimeout) {
                clearTimeout(this.logoutTimeout);
                clearInterval(this.logoutInterval);
            }
            this.logoutTimeout = setTimeout(function () {
                if (_this.showPhonePopup) {
                    if (_this.phoneForm.controls.phone.value) {
                        _this.phoneForm.controls.phone.setValue('');
                        _this.popupComponent.closePopup();
                        _this.screenSaverService.setSaveScreenTimer();
                    }
                    // this.updateLogoutTimer();
                    _this.setLogoutTimer();
                }
                else if (_this.terminalService.isUserPlaying) {
                    _this.terminalService.checkLastUpdates().subscribe(function (data) {
                        var now = data.now;
                        var last = data.last;
                        if ((now - last) > _this.logoutAfterSec) {
                            _this.logout();
                        }
                    }, function () {
                        _this.loaderService.hideAndShowTryAgain();
                    }, function () {
                        // this.updateLogoutTimer();
                        _this.setLogoutTimer();
                    });
                }
                else {
                    _this.logout();
                }
            }, this.logoutAfterSec * 1000);
            // if (this.logoutTimeout) {
            //     let count = 0;
            //     this.logoutInterval = setInterval(() => {
            //         count += 1;
            //         console.log(this.logoutTimeout, count)
            //     }, 1000);
            // }
        }
    };
    LoginComponent.prototype.logout = function () {
        var _this = this;
        var counter = this.logoutAutoConfirmAfterSec;
        var counterInterval = setInterval(function () {
            if (counter) {
                counter -= 1;
                document.querySelector('#logoutTimer').innerHTML = String(counter) + '.';
            }
            else {
                clearInterval(counterInterval);
            }
        }, 1000);
        var logoutTime = this.logoutAfterMin === 5 ? 'five (5)' : this.logoutAfterMin === 2 ? 'two (2)' : '';
        this.popupComponent.showPopupAgreement({ text: "Are you still here? No activity is detected for " + logoutTime + " minutes. We are about to log you out in <span id=\"logoutTimer\">" + counter + "</span>", style: 'swal-agreement no-close-btn btn-continue' }, function (result) {
            if (result === 'timerOut') {
                _this.logoutSinglePage();
            }
            clearInterval(counterInterval);
            _this.setLogoutTimer();
            _this.screenSaverService.setSaveScreenTimer();
        }, this.logoutAutoConfirmAfterSec * 1000);
    };
    LoginComponent.prototype.logoutSinglePage = function () {
        var _this = this;
        this.showPinPopup = false;
        this.showRegistrationPopup = false;
        this.showTerminalConnecting = false;
        this.popupComponent.closePopup();
        this.terminalService.isUserPlaying = false;
        this.showPhonePopup = true;
        if (this.pinForm && this.phoneForm) {
            this.phoneForm.controls.phone.setValue('');
            this.resetPinInput();
        }
        this.authenticationService.logout();
        this.userBalanceService.closePopup();
        setTimeout(function () {
            // this.inputs.toArray()[0].nativeElement.focus();
            document.querySelector('input').focus();
            if (_this.isKeyboard) {
                _this.keyboardService.setActiveInput();
            }
        }, 0);
    };
    LoginComponent.prototype.updateLogoutTimer = function () {
        var _this = this;
        console.log('updateLogoutTimer : sliders');
        this.logoutTimeout = setTimeout(function () {
            _this.setLogoutTimer();
        }, this.logoutAfterSec * 1000);
    };
    LoginComponent.prototype.onPhoneSubmit = function () {
        var _this = this;
        if (this.phoneForm.invalid) {
            return;
        }
        var phone = this.phoneForm.controls.phone.value;
        var country = this.country;
        this.authenticationService.checkPhoneAndSendPin(phone, country).subscribe(function (data) {
            // data.status = false;
            // data.code = AUTH_CODES.terminalNotConnected;
            // data.promoCode = 1;
            try {
                if (data.status) {
                    _this.phoneLastFour = phone.substr(-4);
                    _this.showPhonePopup = false;
                    _this.showPinPopup = true;
                    setTimeout(function () {
                        // this.inputs.toArray()[0].nativeElement.focus();
                        document.querySelector('input').focus();
                        if (_this.isKeyboard) {
                            _this.keyboardService.setActiveInput();
                        }
                    }, 0);
                    if (_this.terminalService.isTerminal()) {
                        _this.screenSaverService.clearSaveScreenTimer();
                    }
                }
                else {
                    if (data.code) {
                        if (data.code == AUTH_CODES.registerNewUser) {
                            _this.errorHandlerRegisterNewUser(data);
                        }
                        else if (data.code == AUTH_CODES.terminalNotConnected) {
                            _this.phoneForm.reset();
                            _this.errorHandlerTerminalNotConnected();
                        }
                        else {
                            _this.popupComponent.showPopupError({ text: data.message });
                        }
                    }
                    else {
                        _this.popupComponent.showPopupError({ text: data.message });
                    }
                }
            }
            catch (e) {
                _this.loaderService.hideAndShowTryAgain();
            }
        }, function (error) {
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            _this.loaderService.hide();
        });
    };
    LoginComponent.prototype.errorHandlerRegisterNewUser = function (data) {
        var _this = this;
        this.showRegistrationPopup = true;
        this.showPinPopup = true;
        this.showPhonePopup = false;
        this.showTerminalConnecting = false;
        this.isPromoCodeRequire = !this.terminalService.isTerminal();
        if (this.terminalService.isTerminal()) {
            this.screenSaverService.clearSaveScreenTimer();
        }
        setTimeout(function () {
            // this.inputs.toArray()[0].nativeElement.focus();
            document.querySelector('input').focus();
            if (_this.isKeyboard) {
                _this.keyboardService.setActiveInput();
            }
        }, 0);
    };
    LoginComponent.prototype.errorHandlerTerminalNotConnected = function () {
        var _this = this;
        this.showRegistrationPopup = false;
        this.showPinPopup = false;
        this.showTerminalConnecting = true;
        this.showPhonePopup = false;
        if (this.terminalService.isTerminal()) {
            this.screenSaverService.clearSaveScreenTimer();
        }
        setTimeout(function () {
            // this.inputs.toArray()[0].nativeElement.focus();
            document.querySelector('input').focus();
            if (_this.isKeyboard) {
                _this.keyboardService.setActiveInput();
            }
        }, 0);
    };
    LoginComponent.prototype.onRegisterNewUser = function () {
        var _this = this;
        var phone = this.phoneForm.controls.phone.value;
        var promoCode = this.pinForm.controls.promoCode.value;
        var country = this.country;
        var pin = this.pinForm.controls.digits.value;
        pin = pin.join('');
        if (promoCode) {
            promoCode = String(promoCode).toLowerCase();
        }
        if (pin.length !== 4) {
            this.popupComponent.showPopupError({ text: 'The Code should consist of 4 numerical characters' });
            return;
        }
        this.authenticationService.register(phone, pin, country, promoCode).subscribe(function (data) {
            try {
                if (data.status) {
                    _this.checkLoginSubmit(data);
                }
                else {
                    _this.popupComponent.showPopupError({ text: data.message });
                }
            }
            catch (e) {
                _this.loaderService.hide();
                _this.popupComponent.showPopupError({ text: 'Sorry there was an error try again later.' });
            }
        }, function (error) {
            _this.loaderService.hide();
            _this.popupComponent.showPopupError({ text: 'Sorry there was an error try again later.' });
        }, function () {
            _this.loaderService.hide();
        });
    };
    LoginComponent.prototype.resendSms = function () {
        this.pinForm.reset();
        this.onPhoneSubmit();
    };
    LoginComponent.prototype.onSubmitPin = function () {
        var _this = this;
        if (this.pinForm.invalid) {
            return;
        }
        var country = this.country;
        var phone = this.phoneForm.controls.phone.value;
        var pin = this.pinForm.controls.digits.value;
        pin = pin.join('');
        if (pin.length !== 4) {
            this.popupComponent.showPopupError({ text: 'The Code should consist of 4 numerical characters' });
            return;
        }
        if (phone && pin && (pin.length === 4) && country) {
            this.authenticationService.login(phone, pin, country).subscribe(function (data) {
                _this.checkLoginSubmit(data);
            }, function (error) {
                _this.loaderService.hideAndShowTryAgain();
            }, function () {
                _this.loaderService.hide();
            });
        }
        else {
            this.loaderService.hideAndShowTryAgain();
        }
    };
    LoginComponent.prototype.checkLoginSubmit = function (data) {
        try {
            if (data.status) {
                this.onUserLogged(data);
            }
            else {
                this.popupComponent.showPopupError({ text: data.message });
                //this.popupGoBack();
            }
        }
        catch (e) {
            this.loaderService.hideAndShowTryAgain();
            //this.popupGoBack();
        }
    };
    LoginComponent.prototype.onUserLogged = function (data) {
        try {
            if (data.status) {
                this.getUserDeposit();
            }
            else {
                this.popupComponent.showPopupError({ text: data.message });
            }
        }
        catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
        this.showPhonePopup = false;
        this.showRegistrationPopup = false;
        this.showPinPopup = false;
        this.showTerminalConnecting = false;
        if (this.isKeyboard) {
            this.keyboardService.keyboardVisible.next(false);
        }
    };
    LoginComponent.prototype.getUserDeposit = function () {
        var _this = this;
        this.userBalanceService.getDepositBalance().subscribe(function (data) {
            _this.handleUserDeposit(data);
        }, function () {
            _this.logoutSinglePage();
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            _this.loaderService.hide();
        });
    };
    LoginComponent.prototype.handleUserDeposit = function (data) {
        try {
            if (data.status) {
                this.paymentService.setCashAppShortName(data.shortName);
                if (+data.balance) {
                    this.userBalanceService.showPopup(data.balance, POPUP_BALANCE_INCREASE_BALANCE_TYPE.deposit, false, false);
                }
                else {
                    if (!this.terminalService.isTerminal()) {
                        this.userBalanceService.showPopup(null, POPUP_BALANCE_INCREASE_BALANCE_TYPE.cashApp, false, false);
                    }
                    else {
                        this.createSenetGamesSession();
                    }
                }
            }
            else {
                this.logoutSinglePage();
                this.popupComponent.showPopupError({ text: data.message });
            }
        }
        catch (e) {
            this.logoutSinglePage();
            this.loaderService.hideAndShowTryAgain();
        }
    };
    LoginComponent.prototype.createSenetGamesSession = function () {
        this.gamesSenetService.getSenetSessionRequest();
    };
    LoginComponent.prototype.popupGoBack = function () {
        var _this = this;
        this.showPhonePopup = true;
        this.showPinPopup = false;
        this.showTerminalConnecting = false;
        this.showRegistrationPopup = false;
        this.resetPinInput();
        this.terminalForm.reset();
        if (this.terminalService.isTerminal()) {
            this.screenSaverService.setSaveScreenTimer();
        }
        setTimeout(function () {
            document.querySelector('input').focus();
            if (_this.isKeyboard) {
                _this.keyboardService.setActiveInput();
            }
        }, 1000);
    };
    LoginComponent.prototype.resetPinInput = function () {
        this.pinForm.reset();
        this.pinForm.get('digits').clear();
        for (var i = 0; i < this.numOfDigits; i++) {
            this.pinForm.get('digits').push(this.formBuilder.control(null, Validators.required));
        }
    };
    LoginComponent.prototype.onTerminalFormSubmit = function () {
        var _this = this;
        var locationId = this.terminalForm.controls.locationId.value;
        this.terminalService.registerTerminal(locationId).subscribe(function (data) {
            _this.checkSubmitTerminalForm(data);
        }, function () {
            _this.popupGoBack();
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            _this.loaderService.hide();
        });
    };
    LoginComponent.prototype.checkSubmitTerminalForm = function (data) {
        try {
            if (data.status) {
                this.popupComponent.showPopupSuccess({ text: data.message });
                this.popupGoBack();
                this.phoneForm.reset();
            }
            else {
                this.popupComponent.showPopupError({ text: data.message });
            }
        }
        catch (e) {
            this.popupGoBack();
            this.loaderService.hideAndShowTryAgain();
        }
    };
    LoginComponent.prototype.check = function (index, field, event) {
        if (isNaN(parseInt(event.key, 10)) && event.key !== 'Backspace') {
            event.preventDefault();
        }
        if (field.value && event.key !== 'Backspace') {
            if (index < this.inputs.toArray().length - 1) {
                this.inputs.toArray()[index + 1].nativeElement.focus();
            }
        }
        else if (event.key === 'Backspace') {
            if (index > 0) {
                field.setValue(null);
                this.inputs.toArray()[index - 1].nativeElement.focus();
            }
            else {
                console.log('first field');
            }
        }
    };
    LoginComponent.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginComponent_Factory() { return new LoginComponent(i0.ɵɵinject(i1.FormBuilder), i0.ɵɵinject(i2.AuthenticationService), i0.ɵɵinject(i3.LoginService), i0.ɵɵinject(i4.Router), i0.ɵɵinject(i2.AuthenticationService), i0.ɵɵinject(i5.LoaderService), i0.ɵɵinject(i6.KeyboardService), i0.ɵɵinject(i7.RememberUserService), i0.ɵɵinject(i8.UserBalanceService), i0.ɵɵinject(i9.PaymentService), i0.ɵɵinject(i10.GamesSenetService), i0.ɵɵinject(i11.TerminalService), i0.ɵɵinject(i12.ScreenSaverService), i0.ɵɵinject(i13.PopupComponent)); }, token: LoginComponent, providedIn: "root" });
    return LoginComponent;
}());
export { LoginComponent };
