<div class="investments-popup investments-popup--balance"
     [class.is-hidden]="showNFTPopup"
     [class.is-active]="popupInfo.show">
    <div class="investments-popup__inner">
        <button class="investments-popup__close"
                *ngIf="popupInfo.canClose"
                (click)="closePopup()"></button>
        <div class="balance-popup">
            <div class="balance-popup__content">
                <ng-container *ngIf="popupInfo.type !== POPUP_BALANCE_INCREASE_BALANCE_TYPE.cashApp">
                    <h5 style="text-transform: uppercase;">{{gameVendorName}} SWEEPS</h5>

                    <div class="balance-popup__balance"
                         *ngIf="popupInfo.type === POPUP_BALANCE_INCREASE_BALANCE_TYPE.deposit; else redeemTitle">
                        AVAILABLE ENTRIES {{popupInfo.balance | currency : 'USD' : 'symbol'}}
                    </div>
                    <ng-template #redeemTitle>
                        <div class="balance-popup__balance">
                            You have <span>{{popupInfo.balance | currency : 'USD' : 'symbol'}}</span> available on {{gameVendorName}}
                        </div>
                    </ng-template>
                </ng-container>

                <ng-container *ngIf="popupInfo.type === POPUP_BALANCE_INCREASE_BALANCE_TYPE.cashApp">
                    <div class="balance-popup__balance">
                        Enter amount for CashApp Payment
                    </div>
                </ng-container>



                <div class="balance-popup__form">
                    <form [formGroup]="balanceForm"
                          (ngSubmit)="onSubmitBalanceForm()"
                          class="form-simple form-simple--shrink form-simple--primary">

                        <div class="form-simple__item form-simple__item--amount">
                            <input mask="separator.0"
                                   thousandSeparator=","
                                   (input)="onInputAmount()"
                                   formControlName="amount"
                                   placeholder="Amount"
                                   [type]="'tel'"
                                   class="form-simple__input"
                                   autocomplete="off">
                            <span>.00</span>
                            <div class="form-simple__available" [class.is-active]="moreThenAvailablePayout">
                                Available {{popupInfo.balance | currency : 'USD' : 'symbol'}}
                            </div>
                        </div>

                        <div class="form-simple__btns"
                             *ngIf="popupInfo.type !== POPUP_BALANCE_INCREASE_BALANCE_TYPE.cashApp">
                            <button class="button"
                                    [disabled]="(+balanceForm.controls.amount.value <= 0) || !+popupInfo.balance">
                                <ng-container *ngIf="popupInfo.type === POPUP_BALANCE_INCREASE_BALANCE_TYPE.deposit">
                                    Add
                                </ng-container>
                                <ng-container *ngIf="popupInfo.type === POPUP_BALANCE_INCREASE_BALANCE_TYPE.redeem">
                                    Redeem
                                </ng-container>
                            </button>
                        </div>

                        <div class="form-simple__btns"
                             *ngIf="popupInfo.type === POPUP_BALANCE_INCREASE_BALANCE_TYPE.cashApp">
                            <button class="button"
                                    [disabled]="+balanceForm.controls.amount.value <= 0">
                                CashApp Pay
                            </button>
                        </div>
                    </form>
                </div>

                <div class="balance-popup__footer">
                    <button class="button balance-popup__sweep-btn" type="button" (click)="playGame()">
                        Play
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<app-investments-nft-popup  *ngIf="showNFTPopup && NFTPopupData"
                            [popupData]="NFTPopupData.data"
                            [popupType]="NFTPopupData.type"
                            (emitDepositConfirm)="onDepositConfirm($event)"
                            (close)="onPopupNFTClose()"></app-investments-nft-popup>
