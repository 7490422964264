export const NFT_POPUP_TYPE = {
  info: 'info',
  mint: 'mint',
  nft: 'nft_polygon',
  bitcoin: 'bitcoin'
}

export const NFT_POPUP_PAYMENT_TYPE = {
  debitCard: 'debitCard',
  cashApp: 'cashApp'
}
