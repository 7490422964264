<router-outlet></router-outlet>
<app-loader></app-loader>
<template #popupGenerate></template>

<div class="senet-iframe">
    <iframe frameborder="0" id="iframe" class="senet-iframe__frame"></iframe>
</div>

<div id="terminalKeyboard" class="terminal-keyboard">
    <div class="keyboard">
        <app-keyboard></app-keyboard>
    </div>
</div>

<app-screen-saver *ngIf="isApp"></app-screen-saver>
