import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    OnInit,
    Output,
    QueryList, ViewChild,
    ViewChildren
} from '@angular/core';

import {ScreenSaverService} from "../../service/screen-saver.service";
import {Router} from "@angular/router";
import {TerminalService} from "../../service/terminal.service";

@Component({
    selector: 'app-screen-saver',
    templateUrl: './screen-saver.component.html',
    styleUrls: ['./screen-saver.component.scss'],
})
export class ScreenSaverComponent implements OnInit {
    showSlider = false;

    sliderInterval;
    slidesDurationSec = 30;

    isApp = this.terminalService.isTerminal();

    CLASSES_NAMES = {
        active: 'is-active',
        prev: 'is-prev'
    };

    @ViewChildren('img') slidesDOM: QueryList<ElementRef>;

    constructor(private terminalService: TerminalService,
                private router: Router,
                private screenSaverService: ScreenSaverService) {

        this.screenSaverService.showing.subscribe((showing) => {
            if (showing) {
                this.initSlider();
            } else {
                this.stopSlider();
            }
        });
    }

    ngOnInit() {
        this.isApp = this.terminalService.isTerminal();
    }

    initSlider() {
        this.showSlider = true;
        const slidesLength = this.slidesDOM.length;

        this.resetClasses();
        this.slidesDOM.forEach((el) => {
            if (+this.getOrder(el) === 1) {
                this.setClass(el, this.CLASSES_NAMES.active);
            }
        });

        this.sliderInterval = setInterval(() => {
            const activeSlide = this.slidesDOM.find(el => el.nativeElement.classList.contains(this.CLASSES_NAMES.active));
            const order = this.getOrder(activeSlide);

            this.resetClasses();

                this.slidesDOM.forEach(el => {
                    const elOrder = +this.getOrder(el);
                    if (order < slidesLength) {
                        switch (elOrder) {
                            case +order + 1: {
                                this.setClass(el, this.CLASSES_NAMES.active);
                                break;
                            }
                            case +order: {
                                this.setClass(el, this.CLASSES_NAMES.prev)
                                break;
                            }
                        }
                    } else {
                        switch (elOrder) {
                            case 1: {
                                this.setClass(el, this.CLASSES_NAMES.active);
                                break;
                            }
                            case slidesLength: {
                                this.setClass(el, this.CLASSES_NAMES.prev)
                                break;
                            }
                        }
                    }
                });
        }, this.slidesDurationSec * 1000);
    }

    stopSlider() {
        this.resetClasses();
        this.showSlider = false;
        clearInterval(this.sliderInterval);
    }

    getOrder(el) {
        return el.nativeElement.getAttribute('data-order');
    }

    setClass(el, className) {
        el.nativeElement.classList.add(className);
    }

    resetClasses() {
        if (this.slidesDOM) {
            this.slidesDOM.forEach(el => el.nativeElement.classList.remove(this.CLASSES_NAMES.active));
            this.slidesDOM.forEach(el => el.nativeElement.classList.remove(this.CLASSES_NAMES.prev));
        }
    }
}

