<div class="overlay">
  <div class="mask">
    <div class="tnfr-modal">
      <div class="tnfr-modal__content">
        <div class="head">
          {{idleHeader}}
        </div>
        <div class="head">
          {{idleMessage}} {{timeToEnd}} seconds.
        </div>
        <div class="modal-btn">
          <button type="button" class="btn" (click)="onCloseDialog.emit()">YES</button>
        </div>
      </div>
    </div>
  </div>
</div>
