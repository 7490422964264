/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-idle.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./user-idle.component";
import * as i3 from "../../_services/auth-config";
import * as i4 from "../user-idle-config";
var styles_UserIdleComponent = [i0.styles];
var RenderType_UserIdleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserIdleComponent, data: {} });
export { RenderType_UserIdleComponent as RenderType_UserIdleComponent };
export function View_UserIdleComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "overlay"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "mask"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [["class", "tnfr-modal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 7, "div", [["class", "tnfr-modal__content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "head"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "head"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " ", " seconds. "])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "modal-btn"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "button", [["class", "btn"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCloseDialog.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["YES"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.idleHeader; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.idleMessage; var currVal_2 = _co.timeToEnd; _ck(_v, 7, 0, currVal_1, currVal_2); }); }
export function View_UserIdleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-user-idle", [], null, null, null, View_UserIdleComponent_0, RenderType_UserIdleComponent)), i1.ɵdid(1, 1294336, null, 0, i2.UserIdleComponent, [i3.AuthConfig, i4.UserIdleConfig], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserIdleComponentNgFactory = i1.ɵccf("app-user-idle", i2.UserIdleComponent, View_UserIdleComponent_Host_0, {}, {}, []);
export { UserIdleComponentNgFactory as UserIdleComponentNgFactory };
