import {Component, HostListener, OnInit} from '@angular/core';
import {AuthenticationService} from "../../modules/auth/_services/authentication.service";
import {NavigationEnd, NavigationStart, Router} from "@angular/router";
import {AppVersionrService} from "../../service/app-version/appVersionr.service";
import {KeyboardService} from "../../service/keyboard.service";

@Component({
    selector: 'app-keyboard',
    templateUrl: './keyboard.component.html',
    styleUrls: ['./keyboard.component.scss']
})
export class KeyboardComponent implements OnInit {
    capitalLetters;
    logoutTimer;
    isUserLogout = true;
    keyboardVisible;
    isKeyboardOnTerminal = false;
    isApp = +localStorage.getItem('isApp');

    constructor(private keyboardService: KeyboardService,
                private auth: AuthenticationService,
                private router: Router,) {
        this.keyboardService.capsLock.subscribe(value => {
            this.capsLockChanged(value);
        });
        this.keyboardService.keyboardVisible.subscribe(value => {
            this.keyboardVisibleChanged(value);
        });
        this.keyboardService.isKeyboardVisible.subscribe(value => {
            this.keyboardOnTerminalVisibleChanged(value);
        });

        //TERMINAL V2
        this.auth.userLoggedStatusChanged.subscribe(val => {
            if (this.isApp) {
                this.isUserLogout = !val;
            }
        });

        this.isUserLogout = !this.auth.isUserLogged();

    }


    ngOnInit() {
        if (this.keyboardService.isKeyboardVisible.value) {
            this.keyboardService.init();
        }

    }

    clickHandle(e) {
        const value = e.currentTarget.title;
        this.keyboardService.onInput(value);

    }

    capsLockChanged(value) {
        this.capitalLetters = value;
    }

    keyboardVisibleChanged(value) {
        this.keyboardVisible = value;
    }

    keyboardOnTerminalVisibleChanged(value) {
        if (value) {
            this.keyboardService.init();
        }
    }
}
