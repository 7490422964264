import * as i0 from "@angular/core";
var RememberUserService = /** @class */ (function () {
    function RememberUserService() {
    }
    RememberUserService.prototype.checkSaveUser = function (userNameControl, passwordControl) {
        if (localStorage.usrname) {
            userNameControl.setValue(localStorage.usrname);
        }
        if (localStorage.password) {
            passwordControl.setValue(localStorage.password);
        }
    };
    RememberUserService.prototype.setSaveUser = function (userName, password) {
        localStorage.usrname = userName;
        localStorage.password = password;
    };
    RememberUserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RememberUserService_Factory() { return new RememberUserService(); }, token: RememberUserService, providedIn: "root" });
    return RememberUserService;
}());
export { RememberUserService };
