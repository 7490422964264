import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {AuthenticationService} from "../modules/auth/_services/authentication.service";
import {KEYBOARD_SETTINGS} from "../config/keyboard_settings";
import {POPUP_BALANCE_INCREASE_BALANCE_TYPE} from "../shared/popup-balance/popup-balance.config";

@Injectable({
    providedIn: 'root'
})
export class KeyboardService {
    inputs = document.querySelectorAll('input[type=text], input[type=tel], input[type=number]');
    activeInput;
    capsLock = new BehaviorSubject(false);
    capsLockReset = false;
    activityTimer = new BehaviorSubject(0);
    logoutTimer;
    wasLogout = new BehaviorSubject(false);

    keyboardVisible = new BehaviorSubject(false);
    isApp = +localStorage.getItem('isApp');
    smallInputValueChanged = new BehaviorSubject({order: 0, value: null});

    isSearch = false;

    searchCursorPosition;
    searchValue;

    win;
    isKeyboardVisibleKey = 'keyboardIsVisible';
    isKeyboardVisible = new BehaviorSubject(false);
    constructor(private router: Router,
                private activatedRoute: ActivatedRoute,
                private auth:AuthenticationService) {}


    init() {
        setTimeout(() => {
            // console.log("test_test: 5");
            this.setActiveInput();
        }, 1000)
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                setTimeout(() => {
                    // console.log("test_test: 6");
                    this.setActiveInput();
                }, 1000)
            }
        });
    }

    setActiveInput() {
        this.inputs = document.querySelectorAll(KEYBOARD_SETTINGS.excludedInputs);

        if (this.inputs.length) {
            this.activeInput = (<HTMLInputElement>this.inputs[0]);

            this.inputs.forEach(el => {
                el.addEventListener('focus', () => {
                    this.activeInput = el;
                });

                el.scrollLeft = el.scrollWidth;
            });

            if (!this.wasLogout.value) {
                this.activeInput.focus();

                this.showKeyboard();
            }
        }
    }

    showKeyboard() {
        if (this.isKeyboardVisible.value) {
            this.keyboardVisible.next(true);
        }

    }

    hideKeyboard() {
        this.keyboardVisible.next(false);
    }

    onInput(value) {
        let val = this.capsLock.value ? value.toUpperCase() : value.toLowerCase();
        let cursorPosition = this.activeInput.selectionStart ? this.activeInput.selectionStart : 0;
        this.activeInput.focus();

        if (this.isSearch) {
            this.win = document.getElementById("iframe")['contentWindow'];
            this.win.postMessage({focus: true},"*");
            cursorPosition = this.searchCursorPosition;
        }

        if (this.capsLockReset && value !== 'up') {
            this.capsLock.next(false);
            this.capsLockReset = false;
        }

        if (value === 'up') {
            this.capsLock.next(!this.capsLock.value);
            this.capsLockReset = true;
            return
        }

        if (this.activeInput.classList.contains('login-input-small') && !this.isSearch) {
            let inputOrder = +(<HTMLInputElement>document.activeElement).getAttribute('data-order');
            const countAllInputs = document.querySelectorAll('.login-input-small').length - 1;

            if (value === 'cancel') {
                (<HTMLInputElement>document.activeElement).value = '';
                this.smallInputValueChanged.next({order: +inputOrder, value: null});
                if (+inputOrder !== 0) {
                    this.setPrevSmallInput(inputOrder);
                }

            } else if (Number(value) || value === "0") {
                if ((<HTMLInputElement>document.activeElement).value) {
                    if (+inputOrder !== countAllInputs) {
                        this.setNextSmallInput(inputOrder);
                        inputOrder += 1;
                    }
                }

                (<HTMLInputElement>document.activeElement).value = value;
                this.smallInputValueChanged.next({order: inputOrder, value: value});
                if (+inputOrder !== countAllInputs) {
                    this.setNextSmallInput(inputOrder);
                }

            }
            return;
        }

        if (value === 'cancel') {
            cursorPosition -= 1;
            let str = (<HTMLInputElement>document.activeElement).value;
            (<HTMLInputElement>document.activeElement).value = this.removeCharacter(str, cursorPosition);
            (<HTMLInputElement>document.activeElement).dispatchEvent(new Event('input'));
            // cursorPosition -= 1;

            this.activeInput.setSelectionRange(cursorPosition, cursorPosition);

            if (this.isSearch) {
                let str = this.searchValue;
                const newVal = this.removeCharacter(str, cursorPosition);
                this.win.postMessage({onInput: true, value: newVal, cursorPosition: cursorPosition},"*");
            }
            return
        }

        let oldValue = (<HTMLInputElement>document.activeElement).value;
        let newValue = oldValue.slice(0, cursorPosition) + val + oldValue.slice(cursorPosition);

        (<HTMLInputElement>document.activeElement).value = newValue;
        (<HTMLInputElement>document.activeElement).dispatchEvent(new Event('input', { bubbles: true }));

        let index = this.activeInput.value.indexOf(val, [cursorPosition]);

        if (index > -1) {
            cursorPosition = index+1;
        }

        let inputType = this.activeInput.getAttribute('type');

        if (inputType === 'email') {
            this.activeInput.setAttribute('type', 'text');
        }
        this.activeInput.setSelectionRange(cursorPosition, cursorPosition);

        if (this.isSearch) {
            let str = this.searchValue;
            const newVal = str.slice(0, cursorPosition) + val + str.slice(cursorPosition);
            this.searchValue = newVal;

            let index = this.searchValue.indexOf(val, [cursorPosition]);

            if (index > -1) {
                cursorPosition = index+1;
            }

            this.win.postMessage({onInput: true, value: newVal, cursorPosition: cursorPosition},"*");
        }
    }

    setPrevSmallInput(inputOrder) {
        let prevInput;
        this.inputs.forEach(el => {
            if (el.getAttribute('data-order') === String(+inputOrder - 1)) {
                prevInput = el;
            }
        });
        this.activeInput = prevInput;
        this.activeInput.focus();
    }

    setNextSmallInput(inputOrder) {
        let nextInput;
        this.inputs.forEach(el => {
            if (el.getAttribute('data-order') === String(+inputOrder + 1)) {
                nextInput = el;
            }
        });
        this.activeInput = nextInput;
        this.activeInput.focus();
    }

    onFocusChanged(el) {
        this.activeInput = el;
        this.activeInput.focus();
    }

    setIsKeyboardVisible(val) {
        // const isKeyboardVisible = val && (val !== '0') ? '1' : '0';
        // sessionStorage.setItem(this.isKeyboardVisibleKey, isKeyboardVisible);
        const isKeyboardVisible = val && (val !== '0') ? 1 : 0;
        this.isKeyboardVisible.next(!!isKeyboardVisible);
        if (!!isKeyboardVisible) {
            document.body.classList.remove('no-keyboard');
        } else {
            document.body.classList.add('no-keyboard');
        }
    }

    getIsKeyboardVisible(val) {
        return !+sessionStorage.getItem(this.isKeyboardVisibleKey);
    }

    removeCharacter(str, char_pos) {
        const part1 = str.substring(0, char_pos);
        const part2 = str.substring(char_pos + 1, str.length);
        return (part1 + part2);
    }

    // var win = document.getElementById("iframe")['contentWindow']; // объект iframe
    // win.postMessage({showPopup: POPUP_BALANCE_INCREASE_BALANCE_TYPE.redeem},"*"); // отправляем сообщение в iframe ( после запятой адресс iframe )

}
