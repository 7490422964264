import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    AfterViewInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren, HostListener, Injectable
} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthenticationService} from "../../modules/auth/_services/authentication.service";
import {Router} from "@angular/router";
import {LoginService} from "../../service/login.service";
import {PopupComponent} from "../../shared/popup/popup.component";
import {slideToRight} from "../../animations/router.animations";
import {LoaderService} from "../../service/loader.service";
import {RememberUserService} from "../../service/rememberUser.service";
import {PHONE_MASK} from "../../config/country_phone_mask";
import {KeyboardService} from "../../service/keyboard.service";
import {AUTH_CODES} from "../../config/auth_codes";
import {UserBalanceService} from "../../service/users/userBalance.service";
import {POPUP_BALANCE_INCREASE_BALANCE_TYPE} from "../../shared/popup-balance/popup-balance.config";
import {GamesSenetService} from "../../service/games/gamesSenet.service";
import {TerminalService} from "../../service/terminal.service";
import {PaymentService} from "../../service/payment.service";
import {ScreenSaverService} from "../../service/screen-saver.service";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    animations: [slideToRight],
    host: {'[@slideToRight]': ''}
})
@Injectable({providedIn: 'root'})
export class LoginComponent implements OnInit {
    isTerminal = !!Number(localStorage.getItem('isApp'));

    pinForm: FormGroup;
    phoneForm: FormGroup;
    terminalForm: FormGroup;

    numOfDigits = 4;
    country = 'us';
    phoneMask = PHONE_MASK[this.country];
    phoneLastFour;

    showPhonePopup = false;
    showPinPopup = false;
    showRegistrationPopup = false;
    showTerminalConnecting = false;

    logoutTimeout;
    logoutAfterMin = 2;
    logoutAfterSec = this.logoutAfterMin * 60;
    logoutAutoConfirmAfterSec = 20;
    isPromoCodeRequire = false;

    @ViewChildren('inputs') inputs: QueryList<ElementRef>;

    isKeyboard = false;
    logoutInterval;
    constructor(
        private formBuilder: FormBuilder,
        private authenticationService: AuthenticationService,
        private loginService: LoginService,
        private router: Router,
        private auth:AuthenticationService,
        private loaderService: LoaderService,
        private keyboardService: KeyboardService,
        private rememberUserService: RememberUserService,
        private userBalanceService: UserBalanceService,
        private paymentService: PaymentService,
        private gamesSenetService: GamesSenetService,
        private terminalService: TerminalService,
        private screenSaverService: ScreenSaverService,
        private popupComponent: PopupComponent) {

        if (this.terminalService.isTerminal()) {
            this.detectActivity();
            this.setLogoutTimer();
            this.screenSaverService.setSaveScreenTimer();

            this.terminalService.isUserActivityIframe.subscribe(val => {
                if (val) {
                    this.setLogoutTimer();
                }
            });

            // this.screenSaverService.logoutCmd.subscribe(val => {
            //     if (val) {
            //         this.logoutSinglePage();
            //     }
            // })
        }

        this.keyboardService.isKeyboardVisible.subscribe(value => {
            this.isKeyboard = value;
            if (value) {
                if ((<FormArray>this.pinForm.controls.digits).controls.length) {
                    this.keyboardService.smallInputValueChanged.subscribe((settings) => {
                        (<FormArray>this.pinForm.controls.digits).controls[settings.order].patchValue(settings.value);
                    });
                }
            }
        });

        this.authenticationService.userLoggedStatusChanged.subscribe(val => {
            if (val) {

            } else {
                this.showPhonePopup = true;
                this.phoneForm = this.formBuilder.group({
                    phone: [null, Validators.required],
                });

                this.terminalForm = this.formBuilder.group({
                    locationId: [null, Validators.required]
                });

                this.pinForm = this.formBuilder.group({
                    digits: this.formBuilder.array([]),
                    promoCode: [null],
                });
                this.userBalanceService.closePopup();
                this.resetPinInput();

                if (this.terminalService.isTerminal()) {
                    this.setLogoutTimer();
                    this.screenSaverService.setSaveScreenTimer();
                }

                if (this.isKeyboard) {
                    this.keyboardService.showKeyboard();
                }
                setTimeout(() => {
                    // this.inputs.toArray()[0].nativeElement.focus();
                    (document.querySelector('input') as HTMLInputElement).focus();
                    if (this.isKeyboard) {
                        this.keyboardService.setActiveInput();
                    }
                }, 0);

            }
        });
    }
    ngOnInit() {
        this.isTerminal = this.terminalService.isTerminal();
        this.auth.logout();
        this.showPhonePopup = true;

        this.phoneForm = this.formBuilder.group({
            phone: [null, Validators.required]
        });

        this.terminalForm = this.formBuilder.group({
            locationId: [null, Validators.required]
        });

        this.pinForm = this.formBuilder.group({
            digits: this.formBuilder.array([]),
            promoCode: [null],
        });
        for (let i = 0; i < this.numOfDigits; i++) {
            (this.pinForm.get('digits') as FormArray).push(this.formBuilder.control(null, Validators.required))
        }
    }

    ngOnDestroy(){
        if (this.terminalService.isTerminal()) {
            this.screenSaverService.clearSaveScreenTimer();
            if (this.logoutTimeout) {
                clearTimeout(this.logoutTimeout);
            }
        }
    }

    detectActivity() {
        setTimeout(() => {
            document.addEventListener('scroll', this.onUserActivity.bind(this));
            document.addEventListener('touchstart', this.onUserActivity.bind(this));
            document.addEventListener('click', this.onUserActivity.bind(this));
            document.addEventListener('keydown', this.onUserActivity.bind(this));
        });
    }

    onUserActivity() {
        this.setLogoutTimer();
        if (this.showPhonePopup) {
            this.screenSaverService.hideScreenSaver();
            setTimeout(() => {
                (document.querySelector('input') as HTMLInputElement).focus();
                if (this.isKeyboard) {
                    this.keyboardService.setActiveInput();
                }
            }, 0);
        } else {
            this.screenSaverService.clearSaveScreenTimer();
        }
    }

    setLogoutTimer() {
        if (this.terminalService.isTerminal()) {
            if (this.logoutTimeout) {
                clearTimeout(this.logoutTimeout);
                clearInterval(this.logoutInterval);
            }
            this.logoutTimeout = setTimeout(() => {
                if (this.showPhonePopup) {
                    if (this.phoneForm.controls.phone.value) {
                        this.phoneForm.controls.phone.setValue('');
                        this.popupComponent.closePopup();
                        this.screenSaverService.setSaveScreenTimer();
                    }
                    // this.updateLogoutTimer();
                    this.setLogoutTimer();

                } else if (this.terminalService.isUserPlaying) {
                    this.terminalService.checkLastUpdates().subscribe(data => {
                        const now = data.now;
                        const last = data.last;
                        if ((now - last) > this.logoutAfterSec ) {
                            this.logout();
                        }
                    }, () => {
                        this.loaderService.hideAndShowTryAgain();
                    }, () => {
                        // this.updateLogoutTimer();
                        this.setLogoutTimer();
                    });
                } else {
                    this.logout();
                }
            }, this.logoutAfterSec * 1000);
            // if (this.logoutTimeout) {
            //     let count = 0;
            //     this.logoutInterval = setInterval(() => {
            //         count += 1;
            //         console.log(this.logoutTimeout, count)
            //     }, 1000);
            // }
        }

    }

    logout() {
        let counter = this.logoutAutoConfirmAfterSec;
        const counterInterval = setInterval(() => {
            if (counter) {
                counter -= 1;
                document.querySelector('#logoutTimer').innerHTML = String(counter) + '.';
            } else {
                clearInterval(counterInterval)
            }
        }, 1000);

        const logoutTime = this.logoutAfterMin === 5 ? 'five (5)' : this.logoutAfterMin === 2 ? 'two (2)' : '';
        this.popupComponent.showPopupAgreement({text: `Are you still here? No activity is detected for ${logoutTime} minutes. We are about to log you out in <span id="logoutTimer">${counter}</span>`, style: 'swal-agreement no-close-btn btn-continue'}, (result) => {
            if (result === 'timerOut') {
                this.logoutSinglePage();
            }
            clearInterval(counterInterval);
            this.setLogoutTimer();
            this.screenSaverService.setSaveScreenTimer();
        }, this.logoutAutoConfirmAfterSec * 1000);
    }

    logoutSinglePage() {
        this.showPinPopup = false;
        this.showRegistrationPopup = false;
        this.showTerminalConnecting = false;
        this.popupComponent.closePopup();

        this.terminalService.isUserPlaying = false;
        this.showPhonePopup = true;

        if (this.pinForm && this.phoneForm) {
            this.phoneForm.controls.phone.setValue('');
            this.resetPinInput();
        }


        this.authenticationService.logout();
        this.userBalanceService.closePopup();

        setTimeout(() => {
            // this.inputs.toArray()[0].nativeElement.focus();
            (document.querySelector('input') as HTMLInputElement).focus();
            if (this.isKeyboard) {
                this.keyboardService.setActiveInput();
            }
        }, 0);

    }

    updateLogoutTimer() {
        console.log('updateLogoutTimer : sliders');
        this.logoutTimeout = setTimeout(() => {
            this.setLogoutTimer();
        }, this.logoutAfterSec * 1000);
    }

    onPhoneSubmit() {
        if (this.phoneForm.invalid) {
            return;
        }

        let phone = this.phoneForm.controls.phone.value;
        let country = this.country;

        this.authenticationService.checkPhoneAndSendPin(phone, country).subscribe(data => {
            // data.status = false;
            // data.code = AUTH_CODES.terminalNotConnected;
            // data.promoCode = 1;
            try {
                if (data.status) {
                    this.phoneLastFour = phone.substr(-4);
                    this.showPhonePopup = false;
                    this.showPinPopup = true;

                    setTimeout(() => {
                        // this.inputs.toArray()[0].nativeElement.focus();
                        (document.querySelector('input') as HTMLInputElement).focus();
                        if (this.isKeyboard) {
                            this.keyboardService.setActiveInput();
                        }
                    }, 0);
                    if (this.terminalService.isTerminal()) {
                        this.screenSaverService.clearSaveScreenTimer();
                    }
                } else {
                    if (data.code) {
                        if (data.code == AUTH_CODES.registerNewUser) {
                          this.errorHandlerRegisterNewUser(data);
                        } else if (data.code == AUTH_CODES.terminalNotConnected) {
                          this.phoneForm.reset();
                          this.errorHandlerTerminalNotConnected();
                        } else {
                            this.popupComponent.showPopupError({text: data.message});
                        }
                    } else {
                        this.popupComponent.showPopupError({text: data.message});
                    }
                }
            } catch (e) {
                this.loaderService.hideAndShowTryAgain();
            }

        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    errorHandlerRegisterNewUser(data) {
        this.showRegistrationPopup = true;
        this.showPinPopup = true;
        this.showPhonePopup = false;
        this.showTerminalConnecting = false;
        this.isPromoCodeRequire = !this.terminalService.isTerminal();
        if (this.terminalService.isTerminal()) {
            this.screenSaverService.clearSaveScreenTimer();
        }

        setTimeout(() => {
            // this.inputs.toArray()[0].nativeElement.focus();
            (document.querySelector('input') as HTMLInputElement).focus();
            if (this.isKeyboard) {
                this.keyboardService.setActiveInput();
            }
        }, 0);
    }

    errorHandlerTerminalNotConnected() {
        this.showRegistrationPopup = false;
        this.showPinPopup = false;
        this.showTerminalConnecting = true;
        this.showPhonePopup = false;
        if (this.terminalService.isTerminal()) {
            this.screenSaverService.clearSaveScreenTimer();
        }

        setTimeout(() => {
            // this.inputs.toArray()[0].nativeElement.focus();
            (document.querySelector('input') as HTMLInputElement).focus();
            if (this.isKeyboard) {
                this.keyboardService.setActiveInput();
            }
        }, 0);
    }

    onRegisterNewUser() {
        let phone = this.phoneForm.controls.phone.value;
        let promoCode = this.pinForm.controls.promoCode.value;
        let country = this.country;
        let pin = this.pinForm.controls.digits.value;
        pin = pin.join('');

        if (promoCode) {
          promoCode = String(promoCode).toLowerCase();
        }

        if (pin.length !== 4) {
            this.popupComponent.showPopupError({text: 'The Code should consist of 4 numerical characters'});
            return
        }

        this.authenticationService.register(phone, pin, country, promoCode).subscribe(data => {
            try {
                if (data.status) {
                    this.checkLoginSubmit(data);
                } else {
                    this.popupComponent.showPopupError({text: data.message});
                }
            } catch (e) {
                this.loaderService.hide();
                this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
            }

        }, (error) => {
            this.loaderService.hide();
            this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
        }, () => {
            this.loaderService.hide();
        });
    }

    resendSms() {
        this.pinForm.reset();
        this.onPhoneSubmit();
    }

    onSubmitPin() {
        if (this.pinForm.invalid) {
            return;
        }

        const country = this.country;
        const phone = this.phoneForm.controls.phone.value;
        let pin = this.pinForm.controls.digits.value;
        pin = pin.join('');

        if (pin.length !== 4) {
            this.popupComponent.showPopupError({text: 'The Code should consist of 4 numerical characters'});
            return
        }

        if (phone && pin && (pin.length === 4) && country) {
            this.authenticationService.login(phone, pin, country).subscribe(data => {
                this.checkLoginSubmit(data);
            }, (error) => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    checkLoginSubmit(data) {
        try {
            if (data.status) {
                this.onUserLogged(data);
            } else {
                this.popupComponent.showPopupError({text: data.message});
                //this.popupGoBack();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
            //this.popupGoBack();
        }
    }

    onUserLogged(data) {
        try {
            if (data.status) {
                this.getUserDeposit();
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
        this.showPhonePopup = false;
        this.showRegistrationPopup = false;
        this.showPinPopup = false;
        this.showTerminalConnecting = false;
        if (this.isKeyboard) {
            this.keyboardService.keyboardVisible.next(false);
        }
    }

    getUserDeposit() {
        this.userBalanceService.getDepositBalance().subscribe(data => {
            this.handleUserDeposit(data);
        }, () => {
            this.logoutSinglePage();
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    handleUserDeposit(data) {
        try {
            if (data.status) {
                this.paymentService.setCashAppShortName(data.shortName);
                if (+data.balance) {
                    this.userBalanceService.showPopup(data.balance, POPUP_BALANCE_INCREASE_BALANCE_TYPE.deposit, false, false);
                } else {
                    if (!this.terminalService.isTerminal()) {
                        this.userBalanceService.showPopup(null, POPUP_BALANCE_INCREASE_BALANCE_TYPE.cashApp, false, false);
                    } else {
                        this.createSenetGamesSession();
                    }
                }
            } else {
                this.logoutSinglePage();
                this.popupComponent.showPopupError({text: data.message});

            }
        } catch (e) {
            this.logoutSinglePage();
            this.loaderService.hideAndShowTryAgain();

        }
    }

    createSenetGamesSession() {
        this.gamesSenetService.getSenetSessionRequest();
    }

    popupGoBack() {
        this.showPhonePopup = true;
        this.showPinPopup = false;
        this.showTerminalConnecting = false;
        this.showRegistrationPopup = false;
        this.resetPinInput();
        this.terminalForm.reset();
        if (this.terminalService.isTerminal()) {
            this.screenSaverService.setSaveScreenTimer();
        }
        setTimeout(() => {
            (document.querySelector('input') as HTMLInputElement).focus();
            if (this.isKeyboard) {
                this.keyboardService.setActiveInput();
            }
        }, 1000);
    }

    resetPinInput() {
        this.pinForm.reset();
        (this.pinForm.get('digits') as FormArray).clear();
        for (let i = 0; i < this.numOfDigits; i++) {
            (this.pinForm.get('digits') as FormArray).push(this.formBuilder.control(null, Validators.required))
        }
    }

    onTerminalFormSubmit() {
        const locationId = this.terminalForm.controls.locationId.value;
        this.terminalService.registerTerminal(locationId).subscribe(data => {
            this.checkSubmitTerminalForm(data);
        }, () => {
            this.popupGoBack();
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    checkSubmitTerminalForm(data) {
        try {
            if (data.status) {
                this.popupComponent.showPopupSuccess({text: data.message});
                this.popupGoBack();
                this.phoneForm.reset();
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.popupGoBack();
            this.loaderService.hideAndShowTryAgain();
        }
    }

    check(index, field, event) {
        if (isNaN(parseInt(event.key, 10)) && event.key !== 'Backspace') {
            event.preventDefault();
        }

        if (field.value && event.key !== 'Backspace') {
            if (index < this.inputs.toArray().length - 1) {
                this.inputs.toArray()[index + 1].nativeElement.focus();
            }
        } else if (event.key === 'Backspace') {
            if (index > 0) {
                field.setValue(null)
                this.inputs.toArray()[index - 1].nativeElement.focus();
            } else {
                console.log('first field');
            }
        }
    }
}

