import { GAMES_SENET_CONFIG } from "../../config/api";
import * as i0 from "@angular/core";
import * as i1 from "../../shared/popup/popup.component";
import * as i2 from "@angular/common/http";
import * as i3 from "../../modules/auth/_services/authentication.service";
import * as i4 from "../loader.service";
var GamesSenetService = /** @class */ (function () {
    function GamesSenetService(popupComponent, http, authenticationService, loaderService) {
        this.popupComponent = popupComponent;
        this.http = http;
        this.authenticationService = authenticationService;
        this.loaderService = loaderService;
        this.config = GAMES_SENET_CONFIG;
    }
    GamesSenetService.prototype.getSenetSession = function () {
        this.loaderService.show();
        var post = this.http.post(this.config.getSenetSession, {});
        return post;
    };
    GamesSenetService.prototype.getSenetSessionRequest = function () {
        var _this = this;
        this.getSenetSession().subscribe(function (data) {
            _this.setSenetSession(data);
        }, function () {
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            // this.loaderService.hide();
        });
    };
    GamesSenetService.prototype.setSenetSession = function (data) {
        var _this = this;
        try {
            if (data.status) {
                var url = data.sessionLink;
                this.openSentFrame(url);
                setTimeout(function () {
                    _this.loaderService.hide();
                }, 3000);
            }
            else {
                this.authenticationService.logout();
                this.popupComponent.showPopupError({ text: data.message });
                this.loaderService.hide();
            }
        }
        catch (e) {
            this.authenticationService.logout();
            this.loaderService.hideAndShowTryAgain();
        }
    };
    GamesSenetService.prototype.openSentFrame = function (url) {
        var iframeWrapper = document.querySelector('.senet-iframe');
        var iframe = document.querySelector('.senet-iframe iframe');
        iframeWrapper.classList.add('is-active');
        // (iframe as HTMLIFrameElement).onload = this.onSenetFrameLoaded.bind(this);
        iframe.src = url;
        // (document.querySelector('app-root') as HTMLElement).classList.add('hide-menu');
    };
    GamesSenetService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GamesSenetService_Factory() { return new GamesSenetService(i0.ɵɵinject(i1.PopupComponent), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.AuthenticationService), i0.ɵɵinject(i4.LoaderService)); }, token: GamesSenetService, providedIn: "root" });
    return GamesSenetService;
}());
export { GamesSenetService };
