<!--<div class="screen-saver" [class.is-active]="showSlider">-->
<!--    <img *ngFor="let slide of slides; let i = index"-->
<!--            #img-->
<!--            class="screen-saver__slide"-->
<!--            [src]="slide"-->
<!--            [attr.data-order]="i+1"-->
<!--            alt="">-->
<!--</div>-->


<div class="screen-saver" [class.is-active]="showSlider">
    <div #img
         [attr.data-order]="1"
         class="screen-saver__slide kiosk-slide">
        <img class="kiosk-slide__img-slide" src="../../../assets/images/screen-saver/slide_1.jpg" alt="">
    </div>

    <div #img
         [attr.data-order]="2"
         class="screen-saver__slide kiosk-slide">
         <img class="kiosk-slide__img-slide" src="../../../assets/images/screen-saver/slide_2.jpg" alt="">
    </div>

    <div #img
         [attr.data-order]="3"
         class="screen-saver__slide kiosk-slide">
        <img class="kiosk-slide__img-slide" src="../../../assets/images/screen-saver/slide_3.jpg" alt="">
    </div>

    <div #img
         [attr.data-order]="4"
         class="screen-saver__slide kiosk-slide">
        <img class="kiosk-slide__img-slide" src="../../../assets/images/screen-saver/slide_4.jpg" alt="">
    </div>

    <div #img
         [attr.data-order]="5"
         class="screen-saver__slide kiosk-slide">
        <img class="kiosk-slide__img-slide" src="../../../assets/images/screen-saver/slide_5.jpg" alt="">
    </div>
</div>
