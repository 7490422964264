//import {routerTransition} from "animations/router.animations";
//import {trigger, state, animate, style, transition} from '@angular/animations';
var DEFAULT_SWIPER_CONFIG = {
    direction: 'horizontal',
    slidesPerView: 'auto',
};
var ɵ0 = DEFAULT_SWIPER_CONFIG;
// import {DemoMaterialModule} from "./material.module";
// import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material";
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
