export class UserIdleConfig {

  static REMEMBER_ME = true;
  /**
   * Idle value in seconds.
   */
  idle: number;
  /**
   * Timeout value in seconds.
   */
  timeout: number;
  /**
   * Ping value in seconds.
   */
  ping: number;

  showIdleDialog: boolean;
}

