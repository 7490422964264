import * as i0 from "@angular/core";
var JwtInterceptor = /** @class */ (function () {
    function JwtInterceptor() {
        this.hasError = false;
        this.refreshingToken = false;
    }
    JwtInterceptor.prototype.intercept = function (request, next) {
        var w = window;
        if (w && w.token) {
            request = request.clone({
                setHeaders: {
                    Authorization: "Bearer " + w.token
                }
            });
        }
        return next.handle(request);
    };
    JwtInterceptor.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function JwtInterceptor_Factory() { return new JwtInterceptor(); }, token: JwtInterceptor, providedIn: "root" });
    return JwtInterceptor;
}());
export { JwtInterceptor };
