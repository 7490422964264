import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
    DateAdapter,
    MAT_DATE_FORMATS,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatRadioModule
} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { NgxMaskModule, IConfig } from 'ngx-mask';

import {APP_DATE_FORMATS, SharedModule} from '../shared/shared.module';
import {PagesModule} from './pages-routing.module';

import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

import { MainComponent } from './main/main.component';

import {LoginComponent} from "./login/login.component";

import {MatSliderModule} from '@angular/material/slider';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {FocusMonitor} from "@angular/cdk/a11y";
import {Platform} from "@angular/cdk/platform";

import { NgxCurrencyModule } from "ngx-currency";
import {DatepickerCustomHeaderExample, ExampleHeader} from "../shared/datapicker-header/datapicker-header";
import {DemoMaterialModule} from "../material.module";
import {NgxDateRangeModule} from "ngx-daterange";
import {CustomDateAdapter} from "../adapters/custom-date-adapter";
import {CashappTestComponent} from "./cashapp-test/cashapp-test.component";

@NgModule({
    declarations: [
        MainComponent,
        LoginComponent,
        DatepickerCustomHeaderExample,
        ExampleHeader,
        CashappTestComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PagesModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        MatRadioModule,
        MatDialogModule,
        SharedModule,
        NgxMaskModule.forRoot(),
        SweetAlert2Module.forRoot(),
        MatSliderModule,
        NgxCurrencyModule,
        DemoMaterialModule,
      NgxDateRangeModule,
    ],
    providers: [
        FocusMonitor,
        Platform,
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS},
        {provide: DateAdapter, useClass: CustomDateAdapter }
    ],
    exports: [
        LoginComponent,
        DatepickerCustomHeaderExample,
        ExampleHeader
    ],
    entryComponents: [
        LoginComponent,
        DatepickerCustomHeaderExample, ExampleHeader
    ]
})
export class ChatQuizModule { }
