import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {LoaderService} from "./loader.service";
import {Router} from "@angular/router";
import {PopupComponent} from "../shared/popup/popup.component";
import {TERMINAL_CONFIG} from "../config/api";
import {KeyboardService} from "./keyboard.service";
import {BehaviorSubject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class TerminalService {
    config = TERMINAL_CONFIG;
    isUserPlaying = false;
    public isUserActivityIframe = new BehaviorSubject(null);

    constructor(private http: HttpClient,
                private loaderService: LoaderService,
                private keyboardService: KeyboardService,
                private popupComponent: PopupComponent,
                private router: Router) {
        this.isAppDevice();
    }

    checkLastUpdates() {
        return  this.http.post<any>(this.config.updatesLast, {});
    }

    detectUserActivity() {
        this.isUserActivityIframe.next(Math.round(+new Date()/1000)+Math.random());
    }

    registerTerminal(locationId: string){
        this.loaderService.show();
        return this.http.post<any>(this.config.registerTerminal, {locationId});
    }

    getSettings(){
        this.loaderService.show();
        return this.http.post<any>(this.config.getSettings, {});
    }

    setSettings() {
        this.getSettings().subscribe(data => {
            try {
                if (data.status) {
                    this.keyboardService.setIsKeyboardVisible(data.keyboard);
                } else {
                    this.popupComponent.showPopupError({text: data.message});
                }
            } catch (e) {
                this.loaderService.hideAndShowTryAgain();
            }
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        })
    }

    isAppDevice() {
        var userAgent = window.navigator.userAgent;
        var userAgent = userAgent.toLowerCase();
        if (userAgent.indexOf('myapp/kiosk') !== -1) {
            localStorage.setItem('isApp', '1');
        }else{
            localStorage.setItem('isApp', '0');
        }

        // if (environment.frontEndTest) {
        //     localStorage.setItem('isApp', '1');
        // }
    }

    isTerminal() {
        return !!Number(localStorage.getItem('isApp'));
    }

    checkActivity() {
        let $this = this;
        setTimeout(function(){
            $this.checkActivity();
        },60*10*1000);

        this.checkActivityNow();
    }

    checkActivityNow(){
        this.http.post<any>(this.config.checkActivity, {}).subscribe(res => {
            if (res.status) {
            }else{
                // this.router.navigate(['/']);
                this.popupComponent.showPopupError({text: res.message});
            }
            return res;
        });
    }

}
