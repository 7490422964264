import { Routes } from '@angular/router';
var ɵ0 = function () { return import("./pages/pages.module.ngfactory").then(function (value) { return value.ChatQuizModuleNgFactory; }); };
var routes = [
    { path: '', loadChildren: ɵ0 },
    { path: '**', redirectTo: 'redirected', pathMatch: 'full', }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0 };
