/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./shared/screen-saver/screen-saver.component.ngfactory";
import * as i3 from "./shared/screen-saver/screen-saver.component";
import * as i4 from "./service/terminal.service";
import * as i5 from "@angular/router";
import * as i6 from "./service/screen-saver.service";
import * as i7 from "./shared/loader/loader.component.ngfactory";
import * as i8 from "./shared/loader/loader.component";
import * as i9 from "./service/loader.service";
import * as i10 from "./shared/keyboard/keyboard.component.ngfactory";
import * as i11 from "./shared/keyboard/keyboard.component";
import * as i12 from "./service/keyboard.service";
import * as i13 from "./modules/auth/_services/authentication.service";
import * as i14 from "@angular/common";
import * as i15 from "./app.component";
import * as i16 from "./service/install.service";
import * as i17 from "ng-connection-service";
import * as i18 from "./shared/popup/popup.component";
import * as i19 from "./service/app-version/appVersionr.service";
import * as i20 from "./service/users/userBalance.service";
import * as i21 from "./service/login.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-screen-saver", [], null, null, null, i2.View_ScreenSaverComponent_0, i2.RenderType_ScreenSaverComponent)), i1.ɵdid(1, 114688, null, 0, i3.ScreenSaverComponent, [i4.TerminalService, i5.Router, i6.ScreenSaverService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { popupGenerateContainer: 0 }), (_l()(), i1.ɵeld(1, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(2, 212992, null, 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-loader", [], null, null, null, i7.View_LoaderComponent_0, i7.RenderType_LoaderComponent)), i1.ɵdid(4, 114688, null, 0, i8.LoaderComponent, [i9.LoaderService], null, null), (_l()(), i1.ɵeld(5, 16777216, [[1, 3], ["popupGenerate", 1]], null, 0, "template", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "senet-iframe"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "iframe", [["class", "senet-iframe__frame"], ["frameborder", "0"], ["id", "iframe"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "div", [["class", "terminal-keyboard"], ["id", "terminalKeyboard"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "keyboard"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "app-keyboard", [], null, null, null, i10.View_KeyboardComponent_0, i10.RenderType_KeyboardComponent)), i1.ɵdid(11, 114688, null, 0, i11.KeyboardComponent, [i12.KeyboardService, i13.AuthenticationService, i5.Router], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(13, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); _ck(_v, 4, 0); _ck(_v, 11, 0); var currVal_0 = _co.isApp; _ck(_v, 13, 0, currVal_0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, [["window", "message"]], function (_v, en, $event) { var ad = true; if (("window:message" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onMessage($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 245760, null, 0, i15.AppComponent, [i5.Router, i16.InstallService, i17.ConnectionService, i13.AuthenticationService, i18.PopupComponent, i19.AppVersionrService, i20.UserBalanceService, i4.TerminalService, i12.KeyboardService, i9.LoaderService, i21.LoginService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i15.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
