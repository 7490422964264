export const PROJECT_CONFIG = {
    defaultAvatar: '../../../../assets/images/default_user.png',
    defaultWomanAvatar: '../../../../assets/images/woman-avatar.jpg',
    defaultImage: '../../../../assets/images/default_image.png',
};

export const PROJECT_MESSAGES = {
    unknownError: 'Sorry there was an error try again later.',
    notAllFieldsAreFilled: 'Please, fill all required fields.',
    incorrectDataFormat: 'Incorrect data format.'
}
export const CRYPTO_AMOUNT_MASK = '1.0-10';
