import {Component, EventEmitter, Input, OnInit, Output,Injectable} from '@angular/core';
import {LoaderService} from "../../service/loader.service";

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})

@Injectable({providedIn: 'root'})
export class LoaderComponent implements OnInit {

    visible = false;

    constructor(private loaderService: LoaderService) {
        this.loaderService.visible.subscribe((value: any) => this.loaderChanged(value));
    }

    ngOnInit() {

    }

    loaderChanged(value) {
        this.visible = value;
    }

}
