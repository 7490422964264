import { BehaviorSubject } from "rxjs";
import * as i0 from "@angular/core";
import * as i1 from "./terminal.service";
var ScreenSaverService = /** @class */ (function () {
    function ScreenSaverService(terminalService) {
        this.terminalService = terminalService;
        this.showing = new BehaviorSubject(false);
        this.animation = new BehaviorSubject('');
        this.logoutCmd = new BehaviorSubject(0);
        this.showScreenSaverAfterMin = 5;
        //showScreenSaverAfterMin = 0.3;
        this.isApp = this.terminalService.isTerminal();
    }
    ScreenSaverService.prototype.logout = function () {
        if (this.isApp) {
            this.logoutCmd.next(Math.round(+new Date() / 1000) + Math.random());
        }
    };
    ScreenSaverService.prototype.hideScreenSaver = function () {
        if (this.isApp) {
            this.setSaveScreenTimer();
            this.hide();
        }
    };
    ScreenSaverService.prototype.setSaveScreenTimer = function () {
        var _this = this;
        if (this.isApp) {
            this.clearSaveScreenTimer();
            this.showScreenSaverTimer = setTimeout(function () {
                console.log("setSaveScreenTimer");
                _this.show();
                // document.body.click();
                _this.logout();
            }, this.showScreenSaverAfterMin * 60 * 1000);
            // if (this.showScreenSaverTimer) {
            //     let count = 0;
            //     this.logoutInterval = setInterval(() => {
            //         count += 1;
            //         console.log(this.showScreenSaverTimer, count)
            //     }, 1000);
            // }
        }
    };
    ScreenSaverService.prototype.clearSaveScreenTimer = function () {
        console.log("clearSaveScreenTimer");
        if (this.showScreenSaverTimer) {
            clearTimeout(this.showScreenSaverTimer);
        }
        if (this.logoutInterval) {
            clearTimeout(this.logoutInterval);
        }
    };
    ScreenSaverService.prototype.show = function () {
        this.showing.next(true);
    };
    ScreenSaverService.prototype.hide = function () {
        this.showing.next(false);
    };
    ScreenSaverService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ScreenSaverService_Factory() { return new ScreenSaverService(i0.ɵɵinject(i1.TerminalService)); }, token: ScreenSaverService, providedIn: "root" });
    return ScreenSaverService;
}());
export { ScreenSaverService };
