import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {TerminalService} from "./terminal.service";

@Injectable({
    providedIn: 'root'
})
export class ScreenSaverService {
    showing = new BehaviorSubject(false);
    animation = new BehaviorSubject('');
    logoutCmd = new BehaviorSubject(0);

    showScreenSaverTimer;
    showScreenSaverAfterMin = 5;
    //showScreenSaverAfterMin = 0.3;

    isApp = this.terminalService.isTerminal();
    logoutInterval;

    constructor(private terminalService: TerminalService) {}

    logout(){
        if (this.isApp) {
            this.logoutCmd.next(Math.round(+new Date() / 1000) + Math.random());
        }
    }

    hideScreenSaver(){
        if (this.isApp) {
            this.setSaveScreenTimer();
            this.hide();
        }
    }

    setSaveScreenTimer() {
        if (this.isApp) {
            this.clearSaveScreenTimer();

            this.showScreenSaverTimer = setTimeout(() => {
                console.log("setSaveScreenTimer");

                this.show();
                // document.body.click();
                this.logout();
            }, this.showScreenSaverAfterMin * 60 * 1000);
            // if (this.showScreenSaverTimer) {
            //     let count = 0;
            //     this.logoutInterval = setInterval(() => {
            //         count += 1;
            //         console.log(this.showScreenSaverTimer, count)
            //     }, 1000);
            // }
        }
    }

    clearSaveScreenTimer() {
        console.log("clearSaveScreenTimer");
        if (this.showScreenSaverTimer) {
            clearTimeout(this.showScreenSaverTimer);
        }
        if (this.logoutInterval) {
            clearTimeout(this.logoutInterval);
        }
    }


    show() {
        this.showing.next(true);
    }

    hide() {
        this.showing.next(false);
    }

}
