<div class="bg is-blurred"></div>
<div class="login">
    <div class="login__inner">
        <div class="popup popup-login"  *ngIf="showPhonePopup || showPinPopup || showTerminalConnecting">
            <div class="popup-login__inner"
                 [class.has-promo]="isPromoCodeRequire && showPinPopup"
                 [class.is-register]="showRegistrationPopup">
                <div class="popup__content"></div>
                <form *ngIf="showPhonePopup" [formGroup]="phoneForm"
                      [class.has-promo]="isPromoCodeRequire && showPinPopup"
                      (ngSubmit)="onPhoneSubmit()" class="popup-login__form">
                    <div class="popup-login__item">
                        <input [mask]="phoneMask"
                               formControlName="phone"
                               placeholder="Phone Number"
                               [type]="'tel'"
                               (keydown.enter)="onPhoneSubmit()"
                               class="login-input js-phone-input input-bordered-mascot"
                               autocomplete="off">
                    </div>

                    <button class="popup-login__btn popup-login__btn--login" [disabled]="!phoneForm?.valid"></button>
                </form>

                <form *ngIf="showTerminalConnecting"
                      [formGroup]="terminalForm"
                      (ngSubmit)="onTerminalFormSubmit()"
                      style="margin-bottom: -1rem"
                      class="popup-login__form">
                    <div class="popup-login__item">
                        <input mask="9*"
                               formControlName="locationId"
                               placeholder="Location Id"
                               [type]="'tel'"
                               (keydown.enter)="onTerminalFormSubmit()"
                               class="login-input"
                               autocomplete="off">
                    </div>

                    <div style="margin-top: 1rem">
                        <button  style="margin-bottom: 0" (click)="popupGoBack()" type="button" class="text t-light login-text login__resend">Back</button>
                    </div>

                    <button class="popup-login__btn popup-login__btn--continue"
                            [disabled]="!terminalForm.valid"></button>
                </form>

                <form *ngIf="showPinPopup" [formGroup]="pinForm" class="popup-login__form">

                    <div *ngIf="showRegistrationPopup && isPromoCodeRequire" class="login-content login-content--mt">
                        <p class="text t-light login__text">Enter your Promo Code</p>
                    </div>

                    <div class="login__item"
                         style="margin-bottom: 2rem"
                         *ngIf="showRegistrationPopup && isPromoCodeRequire">
                        <input formControlName="promoCode"
                               style="text-transform: lowercase"
                               #promoCode
                               [mask]="'A*'"
                               placeholder="Promo Code*"
                               [type]="'text'"
                               class="login-input"
                               autocomplete="off">
                    </div>


                    <div *ngIf="showRegistrationPopup" class="login__text login__text--mb-sm">
                        Your phone number:
                        <span class="accent"> {{phoneForm.controls.phone.value | mask: phoneMask}}</span>
                    </div>
                    <div *ngIf="!showRegistrationPopup" class="login-content login-content--mt">
                        <p class="text t-light login__text">Enter the four digit pin sent to the number ending in -{{phoneLastFour}}.</p>
                    </div>
                    <div *ngIf="showRegistrationPopup" class="login-content">
                        <p class="text t-light login__text login__text--md">Enter the Code we just texted you.</p>
                    </div>

                    <div class="login__items popup-login__items" *ngIf="isKeyboard" formArrayName="digits">
                        <input (keydown.enter)="showRegistrationPopup ? onRegisterNewUser() : onSubmitPin()"
                               *ngFor="let field of pinForm.get('digits')['controls']; let i = index"
                               #inputs [maxLength]="1" [formControlName]="i"
                               [attr.data-order]="i"
                               mask="0"
                               class="login-input-small" type="tel">
                    </div>

                    <div class="login__items popup-login__items" *ngIf="!isKeyboard" formArrayName="digits">
                        <input (keydown.enter)="showRegistrationPopup ? onRegisterNewUser() : onSubmitPin()"
                               *ngFor="let field of pinForm.get('digits')['controls']; let i = index"
                               #inputs [maxLength]="1" [formControlName]="i"  (keydown)="check(i, field, $event)"
                               mask="0"
                               class="login-input-small" type="tel">
                    </div>


<!--                    <div *ngIf="showRegistrationPopup" class="login__text">-->
<!--                        Please click the Login button below to proceed-->
<!--                        <span> <br/> or click<br/>-->
<!--                    <span>The back button to correct your phone number</span>-->
<!--                </span>-->
<!--                    </div>-->

                    <!--            <div *ngIf="showRegistrationPopup" class="login-tab">-->
                    <!--                <div class="text-center form-btn-submit">-->
                    <!--                    <button id="register" class="button snake-btn is-notouched"-->
                    <!--                            (click)="onRegisterNewUser()"-->
                    <!--                            [disabled]="!pinForm.valid">-->
                    <!--                        Register-->
                    <!--                        <svg><rect></rect></svg>-->
                    <!--                    </button>-->
                    <!--                </div>-->
                    <!--            </div>-->

                    <button *ngIf="showRegistrationPopup"
                            (click)="onRegisterNewUser()"
                            class="popup-login__btn popup-login__btn--login"
                            [disabled]="!pinForm.valid || (!pinForm.controls.promoCode.value && !isTerminal)"></button>

                    <div>
                        <button (click)="popupGoBack()" type="button" class="text t-light login-text login__resend">Back</button>
                        <button (click)="resendSms()"
                                type="button"
                                class="text t-light login-text login__resend">Resend</button>
                    </div>

                    <button *ngIf="!showRegistrationPopup"
                            (click)="onSubmitPin()"
                            class="popup-login__btn popup-login__btn--login"
                            [disabled]="!pinForm.valid"></button>
                </form>
            </div>
        </div>
    </div>
</div>
