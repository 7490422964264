export const PHONE_MASK = {
    us: '(000) 000-0000',
    mx: '00 00 0000 0000',
    au: '(00) 0000 0000',
};

export const PHONE_MASK_BY_ID = {
    226: '(000) 000-0000',
    138: '00 00 0000 0000',
    13: '(00) 0000 0000',
};

export const DEFAULT_PHONE_MASK = '(000) 000-0000';
