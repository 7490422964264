import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";

import {LoaderService} from "./loader.service";
import {PAYMENT_CONFIG} from "../config/api";


@Injectable({
    providedIn: 'root'
})
export class PaymentService {
    private config = PAYMENT_CONFIG;

    constructor(private http: HttpClient, public loaderService: LoaderService) {
    }

    setCashAppShortName(name) {
        sessionStorage.setItem('cashAppShortName', name);
    }

    getCashAppShortName() {
        return sessionStorage.getItem('cashAppShortName');
    }

    resetCashAppShortName() {
        sessionStorage.removeItem('cashAppShortName');
    }

    getCartInfo(shortName, amount) {
        this.loaderService.show();
        return this.http.post<any>(this.config.getCartInfo, {shortName, amount});
    }

    buyWithCashApp(shortName, amount, token) {
        this.loaderService.show();
        return this.http.post<any>(this.config.buyWithCashApp, {shortName, amount, token});
    }
}
