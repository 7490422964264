import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {PopupComponent} from "../shared/popup/popup.component";

@Injectable({
    providedIn: 'root'
})
export class LoaderService {
    public visible = new BehaviorSubject(false);

    constructor(private popupComponent: PopupComponent) {

    }

    show() {
        this.visible.next(true);
        document.body.classList.add('is-hidden');

    }

    hide() {
        this.visible.next(false);
        document.body.classList.remove('is-hidden');
    }

    hideAndShowTryAgain(){
        this.hide();
        this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
    }

}
