
<!--<div class="keyboard" [class.keyboard&#45;&#45;centered]="isMascotSingle" *ngIf="keyboardVisible" [class.keyboard&#45;&#45;caps]="capitalLetters">-->
<!--    <button class="keyboard__key keyboard__key&#45;&#45;no-caps" title="7" (click)="clickHandle($event)">7</button>-->
<!--    <button class="keyboard__key keyboard__key&#45;&#45;no-caps" title="8" (click)="clickHandle($event)">8</button>-->
<!--    <button class="keyboard__key keyboard__key&#45;&#45;no-caps" title="9" (click)="clickHandle($event)">9</button>-->

<!--    <button class="keyboard__key keyboard__key&#45;&#45;no-caps" title="4" (click)="clickHandle($event)">4</button>-->
<!--    <button class="keyboard__key keyboard__key&#45;&#45;no-caps" title="5" (click)="clickHandle($event)">5</button>-->
<!--    <button class="keyboard__key keyboard__key&#45;&#45;no-caps" title="6" (click)="clickHandle($event)">6</button>-->

<!--    <button class="keyboard__key keyboard__key&#45;&#45;no-caps" title="1" (click)="clickHandle($event)">1</button>-->
<!--    <button class="keyboard__key keyboard__key&#45;&#45;no-caps" title="2" (click)="clickHandle($event)">2</button>-->
<!--    <button class="keyboard__key keyboard__key&#45;&#45;no-caps" title="3" (click)="clickHandle($event)">3</button>-->

<!--    <button class="keyboard__key keyboard__key&#45;&#45;md keyboard__key&#45;&#45;no-caps" title="cancel" (click)="clickHandle($event)">-->
<!--        <svg xmlns="http://www.w3.org/2000/svg" id="Capa_1" enable-background="new 0 0 428.235 428.235" height="512" viewBox="0 0 428.235 428.235" width="512">-->
<!--            <path d="m388.088 53.529h-241.575c-12.089 0-23.419 5.358-31.091 14.728l-112.403 137.392c-4.025 4.927-4.025 12.01 0 16.937l112.417 137.404c7.658 9.357 18.989 14.715 31.077 14.715h241.575c22.138 0 40.147-18.009 40.147-40.147v-240.881c0-22.139-18.008-40.148-40.147-40.148zm-61.37 208.577-37.847 37.847-47.988-47.988-47.988 47.988-37.847-37.847 47.988-47.988-47.988-47.988 37.847-37.847 47.988 47.988 47.988-47.988 37.847 37.847-47.988 47.988c-.001 0 47.988 47.988 47.988 47.988z"/>-->
<!--        </svg>-->
<!--    </button>-->
<!--    <button class="keyboard__key keyboard__key&#45;&#45;no-caps" title="0" (click)="clickHandle($event)">0</button>-->
<!--</div>-->

<div class="keyboard" *ngIf="keyboardVisible" [class.keyboard--caps]="capitalLetters">
    <div class="keyboard__left">
        <div class="keyboard__row">
            <button class="keyboard__key" title="q" (click)="clickHandle($event)">q</button>
            <button class="keyboard__key" title="w" (click)="clickHandle($event)">w</button>
            <button class="keyboard__key" title="e" (click)="clickHandle($event)">e</button>
            <button class="keyboard__key" title="r" (click)="clickHandle($event)">r</button>
            <button class="keyboard__key" title="t" (click)="clickHandle($event)">t</button>
            <button class="keyboard__key" title="y" (click)="clickHandle($event)">y</button>
            <button class="keyboard__key" title="u" (click)="clickHandle($event)">u</button>
            <button class="keyboard__key" title="i" (click)="clickHandle($event)">i</button>
            <button class="keyboard__key" title="o" (click)="clickHandle($event)">o</button>
            <button class="keyboard__key" title="p" (click)="clickHandle($event)">p</button>
        </div>

        <div class="keyboard__row">
            <button class="keyboard__key" title="a" (click)="clickHandle($event)">a</button>
            <button class="keyboard__key" title="s" (click)="clickHandle($event)">s</button>
            <button class="keyboard__key" title="d" (click)="clickHandle($event)">d</button>
            <button class="keyboard__key" title="f" (click)="clickHandle($event)">f</button>
            <button class="keyboard__key" title="g" (click)="clickHandle($event)">g</button>
            <button class="keyboard__key" title="h" (click)="clickHandle($event)">h</button>
            <button class="keyboard__key" title="j" (click)="clickHandle($event)">j</button>
            <button class="keyboard__key" title="k" (click)="clickHandle($event)">k</button>
            <button class="keyboard__key" title="l" (click)="clickHandle($event)">l</button>
        </div>

        <div class="keyboard__row">
            <button class="keyboard__key" title="z" (click)="clickHandle($event)">z</button>
            <button class="keyboard__key" title="x" (click)="clickHandle($event)">x</button>
            <button class="keyboard__key" title="c" (click)="clickHandle($event)">c</button>
            <button class="keyboard__key" title="v" (click)="clickHandle($event)">v</button>
            <button class="keyboard__key" title="b" (click)="clickHandle($event)">b</button>
            <button class="keyboard__key" title="n" (click)="clickHandle($event)">n</button>
            <button class="keyboard__key" title="m" (click)="clickHandle($event)">m</button>
            <button class="keyboard__key keyboard__key--no-caps" title="," (click)="clickHandle($event)">,</button>
        </div>

        <div class="keyboard__row">
            <button class="keyboard__key keyboard__key--md keyboard__key--no-caps" title="up" (click)="clickHandle($event)">
                <svg *ngIf="!capitalLetters" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                     viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
		            <path d="M445.771,238.813L264.437,4.146c-2.023-2.617-5.235-4.148-8.447-4.146c-3.205,0.003-6.409,1.534-8.428,4.146
			L66.229,238.813c-2.49,3.219-2.927,7.573-1.135,11.219c1.792,3.656,5.5,5.969,9.573,5.969h96v245.333
			c0,5.896,4.771,10.667,10.667,10.667h149.333c5.896,0,10.667-4.771,10.667-10.667V256h96c4.073,0,7.781-2.313,9.573-5.969
			C448.698,246.385,448.26,242.031,445.771,238.813z M330.667,234.667c-5.896,0-10.667,4.771-10.667,10.667v245.333H192V245.333
			c0-5.896-4.771-10.667-10.667-10.667H96.385L256,28.115l159.615,206.552H330.667z"/>
                </svg>
                <svg *ngIf="capitalLetters" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
		            <path d="M445.771,238.813L264.438,4.146c-2.019-2.612-5.223-4.143-8.428-4.146c-3.211-0.003-6.424,1.529-8.447,4.146    L66.229,238.813c-2.49,3.219-2.927,7.573-1.135,11.219c1.792,3.656,5.5,5.969,9.573,5.969h96v245.333    c0,5.896,4.771,10.667,10.667,10.667h149.333c5.896,0,10.667-4.771,10.667-10.667V256h96c4.073,0,7.781-2.313,9.573-5.969    C448.698,246.385,448.26,242.031,445.771,238.813z" style="&#10;    /* fill: aqua; */&#10;    /* stroke: red; */&#10;    /* stroke-width: 10px; */&#10;"/>
                </svg>
            </button>
            <button class="keyboard__key keyboard__key--lg keyboard__key--no-caps" title=" " (click)="clickHandle($event)">space</button>
            <button class="keyboard__key keyboard__key--md keyboard__key--no-caps" title="cancel" (click)="clickHandle($event)">
                <svg xmlns="http://www.w3.org/2000/svg" id="Capa_1" enable-background="new 0 0 428.235 428.235" height="512" viewBox="0 0 428.235 428.235" width="512">
                    <path d="m388.088 53.529h-241.575c-12.089 0-23.419 5.358-31.091 14.728l-112.403 137.392c-4.025 4.927-4.025 12.01 0 16.937l112.417 137.404c7.658 9.357 18.989 14.715 31.077 14.715h241.575c22.138 0 40.147-18.009 40.147-40.147v-240.881c0-22.139-18.008-40.148-40.147-40.148zm-61.37 208.577-37.847 37.847-47.988-47.988-47.988 47.988-37.847-37.847 47.988-47.988-47.988-47.988 37.847-37.847 47.988 47.988 47.988-47.988 37.847 37.847-47.988 47.988c-.001 0 47.988 47.988 47.988 47.988z"/>
                </svg>
            </button>
        </div>


    </div>
    <div class="keyboard__right">
        <button class="keyboard__key keyboard__key--no-caps" title="1" (click)="clickHandle($event)">1</button>
        <button class="keyboard__key keyboard__key--no-caps" title="2" (click)="clickHandle($event)">2</button>
        <button class="keyboard__key keyboard__key--no-caps" title="3" (click)="clickHandle($event)">3</button>
        <button class="keyboard__key keyboard__key--no-caps" title="4" (click)="clickHandle($event)">4</button>
        <button class="keyboard__key keyboard__key--no-caps" title="5" (click)="clickHandle($event)">5</button>
        <button class="keyboard__key keyboard__key--no-caps" title="6" (click)="clickHandle($event)">6</button>
        <button class="keyboard__key keyboard__key--no-caps" title="7" (click)="clickHandle($event)">7</button>
        <button class="keyboard__key keyboard__key--no-caps" title="8" (click)="clickHandle($event)">8</button>
        <button class="keyboard__key keyboard__key--no-caps" title="9" (click)="clickHandle($event)">9</button>
        <button class="keyboard__key keyboard__key--no-caps" title="@" (click)="clickHandle($event)">@</button>
        <button class="keyboard__key keyboard__key--no-caps" title="0" (click)="clickHandle($event)">0</button>
        <button class="keyboard__key keyboard__key--no-caps" title="." (click)="clickHandle($event)">.</button>
    </div>
</div>

