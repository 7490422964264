import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../shared/popup/popup.component";
var LoaderService = /** @class */ (function () {
    function LoaderService(popupComponent) {
        this.popupComponent = popupComponent;
        this.visible = new BehaviorSubject(false);
    }
    LoaderService.prototype.show = function () {
        this.visible.next(true);
        document.body.classList.add('is-hidden');
    };
    LoaderService.prototype.hide = function () {
        this.visible.next(false);
        document.body.classList.remove('is-hidden');
    };
    LoaderService.prototype.hideAndShowTryAgain = function () {
        this.hide();
        this.popupComponent.showPopupError({ text: 'Sorry there was an error try again later.' });
    };
    LoaderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoaderService_Factory() { return new LoaderService(i0.ɵɵinject(i1.PopupComponent)); }, token: LoaderService, providedIn: "root" });
    return LoaderService;
}());
export { LoaderService };
