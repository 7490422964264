export interface PopupRewardsGameInfoModel {
  show: boolean;
  balance: number;
  type: string;
  canClose: boolean;
  isSessionStart?: boolean;
}

export const POPUP_BALANCE_INCREASE_BALANCE_TYPE = {
  redeem: 'redeem',
  deposit: 'deposit',
  login: 'login',
  cashApp: 'cashApp'
};
