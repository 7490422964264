import * as tslib_1 from "tslib";
import { ChangeDetectorRef, ElementRef, EventEmitter, OnInit } from '@angular/core';
import { NFT_POPUP_PAYMENT_TYPE, NFT_POPUP_TYPE } from "./investments-nft-popup.config";
import { CRYPTO_AMOUNT_MASK } from "../../config/project";
// declare var Square: any;
var InvestmentsNftPopupComponent = /** @class */ (function () {
    function InvestmentsNftPopupComponent(loaderService, cdr, cashappPayService, popupComponent) {
        this.loaderService = loaderService;
        this.cdr = cdr;
        this.cashappPayService = cashappPayService;
        this.popupComponent = popupComponent;
        this.close = new EventEmitter();
        this.emitDepositConfirm = new EventEmitter();
        this.POPUP_TYPES = NFT_POPUP_TYPE;
        this.POPUP_PAYMENT_TYPES = NFT_POPUP_PAYMENT_TYPE;
        this.CRYPTO_AMOUNT_MASK = CRYPTO_AMOUNT_MASK;
        this.selectedImage = false;
        this.showTermsAndConditions = false;
        this.showCashApp = false;
    }
    InvestmentsNftPopupComponent.prototype.ngAfterViewChecked = function () {
        this.cdr.detectChanges();
    };
    InvestmentsNftPopupComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.cashappPayService.paymentResult.subscribe(function (tokenResult) {
            console.log(tokenResult);
            if (tokenResult) {
                if (tokenResult.status === 'OK') {
                    console.log("Payment token is " + tokenResult.token);
                    var body = {
                        token: tokenResult.token
                    };
                    // $.post("https://api.dev.eboxenterprises.com/test/square.php?tt=1", body, (data, status) => {
                    //   console.log(data);
                    // });
                    _this.onDepositConfirm(tokenResult.token);
                    // alert('request is here');
                    // console.log(body)
                }
                else {
                    console.error(tokenResult);
                    _this.popupComponent.showPopupError({ text: 'Payment is canceled' });
                    _this.closePopup(false);
                }
                _this.cashappPayService.paymentResult.next(null);
            }
        });
    };
    InvestmentsNftPopupComponent.prototype.closePopup = function (reload) {
        if (reload === void 0) { reload = false; }
        this.close.emit(reload);
        this.selectedImage = false;
        this.showTermsAndConditions = false;
        this.showCashApp = false;
        this.cashappPayService.destroyCashApp();
    };
    InvestmentsNftPopupComponent.prototype.ngOnChanges = function (changes) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log(changes);
                        this.showCashApp = false;
                        if (!this.popupData) return [3 /*break*/, 2];
                        document.body.classList.add('hide-menu');
                        setTimeout(function () {
                            document.body.querySelectorAll('.snake-btn').forEach(function (el) {
                                el.classList.add('is-notouched');
                            });
                            _this.imageUploading();
                        });
                        if (!(this.popupData.payment === this.POPUP_PAYMENT_TYPES.cashApp)) return [3 /*break*/, 2];
                        if (!!this.cashappPayService.isCashAppPayScripExist()) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.cashappPayService.createCashAppPayButton(this.popupData.cashappInfo, this.popupData.element.amount)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    InvestmentsNftPopupComponent.prototype.imageUploading = function () {
        if (document.querySelector('input[type="file"]')) {
            var $this_1 = this;
            document.querySelector('input[type="file"]').addEventListener('change', function () {
                if (this.files && this.files[0]) {
                    var img = document.querySelector('.js-mint-image');
                    img.src = URL.createObjectURL(this.files[0]); // set src to blob url
                    $this_1.selectedImage = true;
                }
            });
        }
    };
    InvestmentsNftPopupComponent.prototype.checkConfirm = function (data) {
        try {
            if (data.status === true) {
                this.popupComponent.showPopupSuccess({ text: data.message });
                this.closePopup();
            }
            else if (data.status === false) {
                this.popupComponent.showPopupError({ text: data.message });
            }
            else {
                this.loaderService.hideAndShowTryAgain();
            }
        }
        catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    };
    InvestmentsNftPopupComponent.prototype.onDepositConfirm = function (token) {
        if (this.popupData.payment === this.POPUP_PAYMENT_TYPES.cashApp) {
            this.emitDepositConfirm.emit({
                amount: this.popupData.element.amount,
                token: token
            });
        }
        else {
            this.emitDepositConfirm.emit({
                amount: this.popupData.element.amount
            });
        }
    };
    InvestmentsNftPopupComponent.prototype.onShowTermsAndConditions = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.showTermsAndConditions = true;
                return [2 /*return*/];
            });
        });
    };
    InvestmentsNftPopupComponent.prototype.checkTermsAgreeUncheck = function () {
        if (this.termsAccept) {
            return !this.termsAccept.nativeElement.checked;
        }
        return true;
    };
    InvestmentsNftPopupComponent.prototype.onShowCashApp = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.showTermsAndConditions = false;
                        this.showCashApp = true;
                        if (!(this.popupData.payment === this.POPUP_PAYMENT_TYPES.cashApp)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.cashappPayService.showCashAppPayButton(this.popupData.element.amount)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    return InvestmentsNftPopupComponent;
}());
export { InvestmentsNftPopupComponent };
